import React, { useState, useEffect } from 'react';
import { Col, CustomInput, Label, Row, FormGroup } from 'reactstrap';
import * as PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateCompanyLogo, getLogo } from '../../store/actions';
import previewImage from '../../images/onboarding-logo-preview.jpg';
// import AhoyTrack from '../../modules/AhoyTrack';

const AddLogo = ({ dispatchUpdateCompanyLogo, companyLogo, dispatchGetLogo }) => {
  const [ imageSrc, setImageSrc ] = useState(null);

	// AhoyTrack("input");

  useEffect(() => {
    dispatchGetLogo();
  }, []);

  const changeImage = (e) => {
    const file = e.target.files[0];
    const fileSize = (file.size / (1024 * 1024)).toFixed(2);
    const validMimeTypes = [
      'image/jpeg', 'image/png', 'image/gif',
    ];

    if (!validMimeTypes.includes(file.type)) {
      toastr.error('Supported files are JPG, PNG, GIF');
      return;
    }

    if (fileSize > 10) {
      toastr.error('File size must be up to 10MB');
      return;
    }

    setImageSrc(URL.createObjectURL(file));
    dispatchUpdateCompanyLogo(file);
  };

  return (
    <>
      <div className="title">Customize your Agent Portal</div>
      <div className="subtitle">
        Your company logo will be displayed on the login screen and the upper left corner of the HubbleIQ Agent Portal. It will be displayed once refresh the page.
      </div>
      <div className="addlogo">
        <Row>
          <Col md="6">
            <div className="addlogo-info">
              <div className="onboarding-block">
                <div className="block-title">Current logo</div>
                <div className="addlogo-logo">
                  { imageSrc && <img src={imageSrc} alt="logo" className="img-responsive" /> }
                  { !imageSrc && companyLogo && <img src={companyLogo} alt="logo" className="img-responsive" /> }
                </div>
                <FormGroup className="form-file mt-3">
                  <Label for="onboarding-logo" className="button button-default">
                    <CustomInput type="file" name="chrome_ex_logo" id="onboarding-logo" onChange={changeImage} />
                    Browse
                  </Label>
                </FormGroup>
              </div>
              <div className="onboarding-block">
                <div className="addlogo-file">
                  <div className="block-title">File upload</div>
                  <ul className="addlogo-file-list">
                    <li>Files supported are JPG, PNG, GIF, up to 10MB file size</li>
                    <li>Recommended dimensions are 275x55</li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="addlogo-preview">
              <div className="preview">
                <img src={previewImage} alt="preview" className="img-responsive" />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

  AddLogo.defaultProps = {
    companyLogo: null,
  };

  AddLogo.propTypes = {
    dispatchUpdateCompanyLogo: PropTypes.func.isRequired,
    companyLogo: PropTypes.string,
  };

  const mapStateToProps = (state) => {
    const { companyLogo } = state.Company;
    return { companyLogo };
  };

  const mapDispatchToProps = {
    dispatchUpdateCompanyLogo: updateCompanyLogo,
    dispatchGetLogo: getLogo,
  };

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddLogo));
