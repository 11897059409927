import { useEffect, useState } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';
import stripe from './stripe.module.scss';
import apiClient from '../../gears/apiClient';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);
// const clientSecret = process.env.REACT_APP_STRIPE_KEY;

export default function Stripe({changeView, planId}) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState('');

  const createPaymentIntent = async () => {
    try {
      const response = await apiClient.post('/payment/create-payment-intent', {
        subscription_id: planId,
      });
      setClientSecret(response.clientSecret);
    } catch(error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY));
    createPaymentIntent();
  }, []);

  return (
    <div className={stripe.formWrapper}>
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{clientSecret}}>
          <StripeCheckoutForm changeView={changeView} />
        </Elements>
      )}
    </div>
  );
};