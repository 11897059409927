import React, { useState } from 'react';
import InviteAdminForm from '../../forms/InviteAdminForm';
import InvitationSent from './InvitationSent';

const InviteAdmins = () => {
  const [ activeTab, setActiveTab ] = useState(0);
  const [ sentTo, setSentTo ] = useState(null);

  const changeActiveTab = (number) => {
    setActiveTab(number);
  };

  const sentToEmail = (email) => {
    setSentTo(email);
  };

  return (
    <>
      <div className="title">Invite other agents to the agent portal</div>
      <div className="subtitle">
        Use the form below to invite others on your team who will be supporting end-users. They will be given access to view performance insights, but will not be able to customize the HubbleIQ system.
      </div>
      {activeTab === 0 && (
        <div className="onboarding-form">
          <InviteAdminForm changeActiveTab={changeActiveTab} sentToEmail={sentToEmail} />
        </div>
      )}
      {activeTab === 1 && (
        <InvitationSent changeActiveTab={changeActiveTab} sentTo={sentTo} isAdmin />
      )}

    </>
  );
};

  export default InviteAdmins;
