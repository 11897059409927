/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable class-methods-use-this */
import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import queryString from 'query-string';
import RefreshButton from '../../components/refresh-button';
import Pagination from '../../components/pagination';
import { fetchTestResults } from '../../store/actions';
import UsersActivitiesTable from '../../components/tables/UsersActivitiesTable';
import ActivitiesTableFilter from '../../components/tables-filters/ActivitiesTableFilter';
import TableDateExport from '../../components/TableDataExport';
import AhoyTrack from '../../modules/AhoyTrack';
import Button from '../../components/Button/Button';
import { InviteModalConsumer } from '../../context/Context';
import { buildPaginationData } from '../../utility/utils';

class Tests extends Component {
  constructor(props) {
    AhoyTrack();
    super(props);
    this.state = {
      filter: null,
      sortBy: null,
    };
  }

  componentDidMount() {
    const {location: { search }} = this.props;
    const filter_by = queryString.parse(search);

    if (filter_by) {
      this.setState({filter: filter_by}, () => this.fetchData());
    } else {
      this.fetchData();
    }
  }

  navigateToPage = (page) => {
    this.fetchData(page);
  }

  onTableSortChange = (sortBy) => {
    if (sortBy) {
      this.setState({
        sortBy,
      }, () => this.fetchData());
    }
  }

  onFilterChange = (filter) => {
    const {history} = this.props;
    history.replace({search: ''});

    this.setState({filter}, () => this.fetchData());
  }

  fetchData = (page) => {
    const { fetchTestResults, history } = this.props;
    const { filter, sortBy } = this.state;
    let queryParams = {};

    if (page) {
      queryParams.page = page;
    }

    if (sortBy) {
      queryParams.sort_by = sortBy;
    }

    if (filter) {
      queryParams = {...queryParams, ...filter};
    }

    fetchTestResults(history, queryParams);
  }

  render() {
    const { data, loading } = this.props;
    let paginationData = null;
    if (data?.activities?.length > 0) {
      paginationData = buildPaginationData(data);
    }

    const tableData = data?.activities || data?.user_activities || [];

    return (
      <InviteModalConsumer>
        {(toggleModal) => {
          return (
            <div className="container-fluid content">
              <Row className="page-title align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h2>Test Results</h2>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="test-results-button">
                    <Button generalType="default" type="button" onClick={() => toggleModal('test')}>Request a test</Button>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center test-results-table-container">
                <Col xl={12}>
                  <div className="justify-between align-items-center mb-4 d-md-flex">
                    <div className="align-items-center d-md-flex">
                      <div className="d-flex align-items-center mr-5">
                        <span className="card-title mb-0">Recent Sessions</span>
                        <RefreshButton onRefresh={() => this.fetchData()} />
                      </div>
                    </div>
                  </div>
                  <div className="justify-between  mb-4 d-md-flex">
                    <ActivitiesTableFilter onFilterChange={this.onFilterChange} />
                  </div>
                  <div className="table-responsive">
                    <UsersActivitiesTable data={tableData} isLoading={loading} onSortChange={this.onTableSortChange} />
                  </div>
                  {(data?.activities?.length > 0 && paginationData) && <Pagination paginationData={paginationData} navigateTo={this.navigateToPage} />}
                  <TableDateExport />
                </Col>
              </Row>
            </div>
          );
        }}
      </InviteModalConsumer>
    );
  }
}

const mapStatetoProps = (state) => {
  const { error, loading, data } = state.TestResults;
  return { error, loading, data };
};

export default withRouter(connect(mapStatetoProps, { fetchTestResults })(Tests));
