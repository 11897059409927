import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';
import { fetchAnalytics } from '../../store/actions';
import IconNoWifi from '../../images/icon-wifi.svg';
import DashboardCard from './DashboardCard';

const PresistentIssuses = () => {
  const {
    persistent_network_issues: presistNetworkIssues,
    persistent_network_issues_isLoading: presistNetworkIssuesIsLoading,
  } = useSelector((state) => state.DashboardAnalytics);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAnalytics('help_request_analytics'));
    dispatch(fetchAnalytics('persistent_network_issues'));
    dispatch(fetchAnalytics('persistent_application_issues'));
  }, []);

  const spinner = <div className="spinenr-wrapper text-center"><Spinner color="primary" /></div>;

  return (
    <Row>
      {presistNetworkIssues > 0 && (
        <Col md="6" lg="4" className="mb-3 mb-lg-0">
          {presistNetworkIssuesIsLoading && spinner}
          {!presistNetworkIssuesIsLoading && (
            <DashboardCard
              cardMod="secondary"
              bgColor="purple"
              cardBlocks={[
                {
                  icon: IconNoWifi,
                  iconClass: 'white',
                  linkText: 'Users with Network Stability Issues',
                  linkUrl: '/admin/tests?filter_by=user_at_least_3_issues',
                  value: presistNetworkIssues,
                },
              ]}
            />
          )}
        </Col>
      )}
    </Row>
  );
};

export default PresistentIssuses;
