import React from 'react';
import { Card, CardBody } from 'reactstrap';

const DashboardAppCard = ({ app: { name, avg_response_time, avg_load_time, outage, url } }) => {
  const colorByStatus = (value) => {
    if (value < 400) {
      return 'good';
    }
    if (value >= 400 && value <= 1000) {
      return 'descent';
    }
    if (value > 1000) {
      return 'poor';
    }
    return '';
  };

  const avgPageLoadTimeColor = (value) => {
    if (value >= 0 && value <= 5) {
      return 'good';
    }
    if (value > 5 && value <= 10) {
      return 'descent';
    }
    if (value > 10) {
      return 'poor';
    }
    return '';
  };

  return (
    <Card className="dashboard-card dashboard-card-app">
      <div className="dashboard-card-line" />
      <CardBody className="dashboard-card-body">
        <div className="dashboard-card-title">{name}</div>
        <a href={url} target="_blank" rel="noreferrer" className="dashboard-card-link">{url}</a>
        <div className="dashboard-card-list list">
          <div className="list-item">
            <div className="list-item-name">Average Response Time</div>
            <div className={`list-item-value ${colorByStatus(avg_response_time)}`}>
              {avg_response_time
              ? (
                <>{avg_response_time}<span>ms</span></>
              )
              : 'NA'}
            </div>
          </div>
          <div className="list-item">
            <div className="list-item-name">Average Page Load Time</div>
            <div className={`list-item-value ${avgPageLoadTimeColor(avg_load_time)}`}>
              {avg_load_time
                ? (
                  <>{avg_load_time}<span>s</span></>
                )
                : 'NA'}
            </div>
          </div>
          <div className="list-item">
            <div className="list-item-name">Experienced Outages</div>
            <div className="list-item-value">{outage}</div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DashboardAppCard;
