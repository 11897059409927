import React from 'react';
import downloadImage from '../../images/onboarding-download.jpg';
import InstallExtensionBtn from "../InstallExtensionBtn/InstallExtensionBtn";

const Download = () => {
  return (
    <>
      <div className="title">Download your HubbleIQ+ Extension</div>
      <div className="subtitle">
        Use the button below to download and install HubbleIQ+ extension to your device. The download link is unique to you, and you will be able to invite other users in the next step.
      </div>
      <div className="download">
        <div className="download-img">
          <img src={downloadImage} alt="extension preview" className="img-responsive" />
        </div>
        <InstallExtensionBtn type="color" text="Click Here To Download" />
      </div>

    </>
  );
};


  export default Download;
