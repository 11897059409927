import React from 'react';
import { Card, CardBody } from 'reactstrap';

const DashboardDeviceCard = ({ device, title, lastRestartTime }) => {
  const keys = Object.keys(device ?? '');
  const values = Object.values(device ?? '');
  const isLink = ['less_than_threshold', 'more_than_threshold', 'no_restart'];

  const renderChart = () => {
    const copyValues = [...values];
    const sum = values.reduce((a, b) => a + b, 0);
    if (sum < 100) {
      const newValues = copyValues?.map((value) => (value * 100) / sum);
      return (
        <div className="bar-chart">
          {newValues?.map((value, i) => (
            <div className={`child${i}`} key={i} style={{width: `${value}%`}} />
            ))}
        </div>
      );
    }
    return (
      <div className="bar-chart">
        {values?.map((value, i) => (
          <div className={`child${i}`} key={i} style={{width: `${value}%`}} />
          ))}
      </div>
    );
  };

  const renderChartFromArr = () => {
    const copyArr = [...device?.result];
    const sum = device?.result?.map((item) => item?.count).reduce((prev, curr) => prev + curr, 0);
    if (sum < 100) {
      const newValues = copyArr?.map((value) => (value?.count * 100) / sum);
      return (
        <div className="bar-chart">
          {newValues?.map((value, i) => (
            <div className={`child${i}`} key={i} style={{width: `${value}%`}} />
            ))}
        </div>
      );
    }
    return (
      <div className="bar-chart">
        {device?.result?.map((value, i) => (
          <div className={`child${i}`} key={i} style={{width: `${value?.count}%`}} />
          ))}
      </div>
    );
  };

  const renderName = (name) => {
    const newName = name.split('_')[0];
    if (newName === 'na' || newName === 'no') {
      return 'not available';
    }
    if (newName === 'more') {
      return `More than ${lastRestartTime} ${lastRestartTime !== 1 ? 'days' : 'day'} Ago`;
    }
    if (newName === 'less') {
      return `Less than ${lastRestartTime} ${lastRestartTime !== 1 ? 'days' : 'day'} Ago`;
    }
    return newName;
  };

  const renderTitle = (title) => {
    if (title === 'cpu_utilization') {
      return 'CPU Utilization';
    }
    if (title === 'battery_health') {
      return 'Battery Health';
    }
    if (title === 'operating_system_category') {
      return 'Operating System';
    }
    if (title === 'users_last_restart_time_threshold') {
      return 'Last Device Restart';
    }
    const newTitle = title?.replace(/_/g, ' ');
    return newTitle;
  };

  const renderLinkUrl = (itemName) => {
    switch (itemName) {
      case 'more_than_threshold':
        return '/admin/users?search_by=last_restart_time&keyword=more_than_threshold';
      case 'less_than_threshold':
        return '/admin/users?search_by=last_restart_time&keyword=less_than_threshold';
      case 'no_restart':
        return '/admin/users?search_by=last_restart_time&keyword=no_restart';
      default:
        return '/admin/users';
    }
  };

  const renderItem = (itemName, index) => {
    if (isLink.includes(itemName)) {
      return (
        <a href={renderLinkUrl(itemName)} className="list-item" key={index}>
          <span className={`list-item-name child${index}`}>{renderName(itemName)}</span>
          <span className="list-item-value">{values[index]}%</span>
        </a>
      );
    }
    return (
      <div className="list-item" key={index}>
        <div className={`list-item-name child${index}`}>{renderName(itemName)}</div>
        <div className="list-item-value">{values[index]}%</div>
      </div>
    );
  };

  return (
    <Card className="dashboard-card dashboard-card-app dashboard-card-device">
      <div className="dashboard-card-line" />
      <CardBody className="dashboard-card-body">
        <div className="dashboard-card-title">{renderTitle(title)}</div>
        {
          device?.result
          ? renderChartFromArr()
          : renderChart()
        }
        <div className="dashboard-card-list list">
          {!device?.result
            ? keys?.map((key, i) => renderItem(key, i))
            : (
              device?.result?.map((os, i) => (
                <div className="list-item" key={i}>
                  <div className={`list-item-name child${i}`}>{os?.operating_system}</div>
                  <div className="list-item-value">{os?.count}%</div>
                </div>
              ))
            )}
        </div>
      </CardBody>
    </Card>
  );
};

export default DashboardDeviceCard;
