import * as type from './actionTypes';

export const getMyProfile = (callback, history) => {
  return {
    type: type.GET_MY_PROFILE_REQUEST,
    callback,
    history,
  };
};

export const getMyProfileSuccess = (payload) => {
  return {
    type: type.GET_MY_PROFILE_SUCCESS,
    payload,
  };
};

export const getMyProfileError = (error) => {
  return {
    type: type.GET_MY_PROFILE_ERROR,
    payload: error,
  };
};

export const updateMyProfile = (payload, history, callback) => {
  return {
    type: type.UPDATE_MY_PROFILE_REQUEST,
    payload,
    history,
    callback,
  };
};

export const updateMyProfileSuccess = (payload) => {
  return {
    type: type.UPDATE_MY_PROFILE_SUCCESS,
    payload,
  };
};

export const updateMyProfileError = (error) => {
  return {
    type: type.UPDATE_MY_PROFILE_ERROR,
    payload: error,
  };
};

export const updateLastRestartTime = (payload) => {
  return {
    type: type.UPDATE_LAST_RESTART_TIME,
    payload,
  };
};
