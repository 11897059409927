import React, { useState } from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { Button, Spinner } from 'reactstrap';
import Welcome from './Welcome';
import AddLogo from './AddLogo';
import Download from './Download';
import Finish from './Finish';
import EnrollUsers from './EnrollUsers';
import InviteAdmins from './InviteAdmins';
import CustomizeExtension from './CustomizeExtension';
import AddApp from './AddApp';

const Onboarding = ({ closeOnboardingModal, adminRole, loading }) => {
  const [ currentStep, setCurrentStep ] = useState(0);

  let steps;
  let content;

  if (adminRole === 'admin') {
    steps = [
      'Welcome', 'Download HubbleIQ+', 'Enroll users', 'Finish',
    ];
    content = {
      0: <Welcome />,
      1: <Download />,
      2: <EnrollUsers />,
      3: <Finish closeOnboardingModal={closeOnboardingModal} hide />,
    };
  } else {
    steps = [
      'Welcome', 'Download HubbleIQ+', 'Invite Admins', 'Enroll users', 'Add logo', 'Customize Extension', 'Add An Application', 'Finish',
    ];
    content = {
      0: <Welcome />,
      1: <Download />,
      2: <InviteAdmins />,
      3: <EnrollUsers />,
      4: <AddLogo />,
      5: <CustomizeExtension />,
      6: <AddApp />,
      7: <Finish closeOnboardingModal={closeOnboardingModal} />,
    };
  }

  return (
    <div className="onboarding">
      <div className="onboarding-stepper">
        {
        loading
          ? <Spinner />
          : (
            <Stepper activeStep={currentStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          )
        }
      </div>
      <div className="onboarding-content">
        <div className="onboarding-step">
          {content[currentStep]}
        </div>
      </div>
      <div className="onboarding-actions">
        {currentStep !== 0 && (
          <Button color="link btn-back" onClick={() => setCurrentStep(currentStep - 1)}>
            <i className="mdi mdi-arrow-left text-primary font-size-20" />
            Back
          </Button>
        )}
        {currentStep !== steps.length - 1 && (
          <Button color="link btn-next" onClick={() => setCurrentStep(currentStep + 1)}>
            Next
            <i className="mdi mdi-arrow-right text-primary font-size-20" />
          </Button>
        )}
        {currentStep === steps.length - 1 && (
          <Button color="link btn-next" onClick={() => closeOnboardingModal()}>
            Finish
          </Button>
        )}
      </div>
    </div>
  );
};

export default Onboarding;
