import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const DashboardCard = ({ cardMod, bgColor, cardTitle, cardBlocks }) => {
  const renderImage = (value) => {
    const isImage = typeof value === 'string';
    const image = isImage
      ? <img src={value} alt="icon" className="img-responsive" />
      : value;
    return image;
  };

  const cardBody = (card, isLink = false) => {
    return (
      <>
        {card?.icon && (
          <span className={`dashboard-card-icon${card?.iconClass ? ` dashboard-card-icon_${card?.iconClass}` : null}`}>
            {renderImage(card?.icon)}
          </span>
        )}
        <span className="dashboard-card-info">
          <span className="dashboard-card-link">
            {card?.linkText}
            {!isLink && <span className="icon"><i className="mdi mdi-open-in-new" /></span>}
          </span>
          <span className="dashboard-card-value">{card?.value || 0}</span>
        </span>
      </>
    );
  };

  const renderCardInner = (card) => {
    const isLink = card?.linkUrl;
    const inner = isLink
      ? <Link to={card?.linkUrl} className="dashboard-card-block">{cardBody(card)}</Link>
      : <div className="dashboard-card-block">{cardBody(card, 'noLinkIcon')}</div>;
    return inner;
  };

  return (
    <Card className={`dashboard-card${bgColor ? ` dashboard-card_${bgColor}` : ''}${cardMod ? ` dashboard-card_${cardMod}` : ''}`}>
      {cardMod === 'application' && <div className="dashboard-card-line" />}
      <CardBody className="dashboard-card-body">
        {cardTitle && <div className="dashboard-card-title">{cardTitle}</div>}
        <Row className="justify-content-center">
          {cardBlocks?.map((card, i) => (
            cardBlocks?.length === 1
            ? (
              <Col md="12" key={i}>
                {renderCardInner(card)}
              </Col>
            )
            : (
              <Col sm="6" key={i} className="col-separate mb-3 mb-sm-0">
                {renderCardInner(card)}
              </Col>
            )
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};

export default DashboardCard;
