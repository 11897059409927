import {
  FETCH_COMPANY_DETAILS, FETCH_COMPANY_DETAILS_SUCCESS, FETCH_COMPANY_DETAILS_API_ERROR,
  FETCH_COMPANY_LOGO, FETCH_COMPANY_LOGO_SUCCESS, FETCH_COMPANY_LOGO_API_ERROR,
  FETCH_COMPANY_DETAILS_WEB_TEST_STATUS,
  FETCH_USER_IP, FETCH_USER_IP_SUCCESS, FETCH_USER_IP_ERROR,
  UPDATE_COMPANY_LOGO, UPDATE_COMPANY_LOGO_SUCCESS, UPDATE_COMPANY_LOGO_ERROR,
  GET_LOGO, GET_LOGO_SUCCESS, GET_LOGO_ERROR,
} from './actionTypes';

export const fetchCompanyDetails = (history, data) => {
  return {
    type: FETCH_COMPANY_DETAILS,
    payload: { data, history },
  };
};

export const fetchCompanyDetailsSuccess = (payload) => {
  return {
    type: FETCH_COMPANY_DETAILS_SUCCESS,
    payload,
  };
};

export const fetchCompanyDetailsApiError = (error) => {
  return {
    type: FETCH_COMPANY_DETAILS_API_ERROR,
    payload: error,
  };
};

export const setWebTestStatus = (state) => {
  return {
    type: FETCH_COMPANY_DETAILS_WEB_TEST_STATUS,
    payload: state,
  };
};

// Company logo
export const fetchCompanyLogo = (history, data) => {
  return {
    type: FETCH_COMPANY_LOGO,
    payload: { data, history },
  };
};

export const fetchCompanyLogoSuccess = (payload) => {
  return {
    type: FETCH_COMPANY_LOGO_SUCCESS,
    payload,
  };
};

export const fetchCompanyLogoApiError = (error) => {
  return {
    type: FETCH_COMPANY_LOGO_API_ERROR,
    payload: error,
  };
};

// Logo
export const getLogo = (history, data) => {
  return {
    type: GET_LOGO,
    payload: { data, history },
  };
};

export const getLogoSuccess = (payload) => {
  return {
    type: GET_LOGO_SUCCESS,
    payload,
  };
};

export const getLogoError = (error) => {
  return {
    type: GET_LOGO_ERROR,
    payload: error,
  };
};

// Update logo
export const updateCompanyLogo = (payload) => {
  return {
    type: UPDATE_COMPANY_LOGO,
    payload,
  };
};

export const updateCompanyLogoSuccess = (payload) => {
  return {
    type: UPDATE_COMPANY_LOGO_SUCCESS,
    payload,
  };
};

export const updateCompanyLogoError = (error) => {
  return {
    type: UPDATE_COMPANY_LOGO_ERROR,
    payload: error,
  };
};

// User ip
export const fetchUserIp = (history, data) => {
  return {
    type: FETCH_USER_IP,
    payload: { data, history },
  };
};

export const fetchUserIpSuccess = (payload) => {
  return {
    type: FETCH_USER_IP_SUCCESS,
    payload,
  };
};

export const fetchUserIpError = (error) => {
  return {
    type: FETCH_USER_IP_ERROR,
    payload: error,
  };
};
