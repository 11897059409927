import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import {fetchAnalytics, fetchRecentActivities} from '../../store/actions';
import AhoyTrack from '../../modules/AhoyTrack';
import DashboardBlock from '../../components/dashboard/DashboardBlock';
import MapBox from '../../components/MapBox/MapBox';
import DashboardDevices from '../../components/dashboard/DashboardDevices';
import DashboardOutage from '../../components/dashboard/DashboardOutage';
import DashboardAplications from '../../components/dashboard/DashboardAplications';
import PresistentIssuses from '../../components/dashboard/PresistentIssuses';
import TestDetailsDrawer from '../../components/drawers/TestDetailsDrawer';

const Dashboard = () => {
  const { data: activitiesData, loading: isRecentActivitiesLoading } = useSelector((state) => state.RecentActivities);
  const [testId, setTestId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    AhoyTrack();
    dispatch(fetchRecentActivities(history));
    dispatch(fetchAnalytics('active_user_result'));
  }, []);

  const onMapMarkerClick = (id) => {
    setTestId(id);
    setIsDrawerOpen(true);
  };

  const onDrawerClose = () => {
    setTestId(null);
    setIsDrawerOpen(false);
  };

  return (
    <>
      <div className="container-fluid content dashboard">
        <Row className="page-title align-items-center">
          <Col sm={12}>
            <div className="page-title-box">
              <h2>Recent <span>24H</span></h2>
            </div>
          </Col>
        </Row>

        <DashboardBlock>
          {isRecentActivitiesLoading && <div className="spinner-wrapper text-center"><Spinner color="primary" /></div>}
          {!isRecentActivitiesLoading && <MapBox markers={activitiesData?.features || []} onMapMarkerClick={onMapMarkerClick} />}
          <div className="dashboard-block-link">
            <Link to="/admin/broadband-insights" className="button button-default-outlined">View Full Map</Link>
          </div>
        </DashboardBlock>

        <DashboardBlock
          title="Application Disruptions"
          subtitle="An indication of applications and services that aren’t reachable by your remote users. Recent outages represent the past 24 hours. Current outages indicate outages that are likely ongoing."
        >
          <DashboardOutage />
        </DashboardBlock>

        <DashboardBlock
          title="Persistent Issues/ Network Stability"
          subtitle="Aggregate and display the count of unique users who are experiencing persistent issues, encountering network stability issues."
        >
          <PresistentIssuses />
        </DashboardBlock>

        <DashboardBlock
          title="Application Performance Summary"
          subtitle="An aggregate summary of general application experience across your remote users. Note: Upon initial setup, data may take a couple of hours to appear below."
        >
          <DashboardAplications />
          <div className="dashboard-block-link">
            <Link to="/admin/applications-settings" className="button button-default-outlined">Edit Applications</Link>
          </div>
        </DashboardBlock>

        <DashboardBlock
          title="Device Performance Summary"
          subtitle="An aggregate summary of general device performance across your remote users. Note: Upon initial setup, data may take a couple of hours to appear below."
        >
          <DashboardDevices />
          <div className="dashboard-block-link">
            <Link to="/admin/device-events" className="button button-default-outlined">Full Details</Link>
          </div>
        </DashboardBlock>
      </div>
      <TestDetailsDrawer
        isOpen={isDrawerOpen}
        onHandlerClose={onDrawerClose}
        testId={testId}
      />
    </>
  );
};

export default Dashboard;
