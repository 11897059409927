import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getApplications, addApplication, deleteApplication, updateApplication } from '../../store/actions';
import ApplicationEditCard from '../ApplicationEditCard';
import ApplicationAddCard from '../ApplicationAddCard';

const AddApp = ({
  data,
  dispatchGetApplications,
  dispatchAddApplication,
  dispatchDeleteApplication,
  dispatchUpdateApplication,
}) => {
  const [applications, setApplications] = useState(data);
  const appsEndRef = useRef(null);

  useEffect(() => {
    dispatchGetApplications();
}, []);

  useEffect(() => {
      setApplications(data);
  }, [data]);

  const addApp = (obj) => {
    dispatchAddApplication({
      name: obj.name,
      url: obj.url,
    });
  };

  const deleteApp = (id) => {
    dispatchDeleteApplication({id});
    const newApplications = [...applications].filter((app) => app.id !== id);
    setApplications(newApplications);
  };

  const saveApp = (data) => {
    dispatchUpdateApplication(data);
  };

  const fetchAppStatus = async (url) => {
    return fetch(url, {mode: 'no-cors'})
    .then(() => {
      return 'good';
    })
    .catch(() => {
      return 'poor';
    });
  };

  const scrollToBottom = () => {
    appsEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [applications]);

  const inputsData = [
    {
      name: '',
      input_name: 'name',
      placeholder: 'Application Name',
      type: 'text',
    },
    {
      name: '',
      input_name: 'url',
      placeholder: 'Input URL or IP Address',
      type: 'url',
    },
  ];

  return (
    <>
      <div className="title">Application Performance & Availability Monitoring</div>
      <div className="subtitle">
        HubbleIQ will monitor how reachable your business apps are to your end-users. Instantly detect if there is a widespread outage, regional performance issues, or LAN issues that are keeping your users from accessing the apps below.
      </div>
      <div className="addApp app-settings">
        <div className="application-cards">
          <div className="application-cards-list">
            <div className="application-cards-scroll">
              {applications.length > 0 && (
                applications?.map((app) => (
                  <ApplicationEditCard
                    app={app}
                    deleteApp={deleteApp}
                    key={app.id}
                    fetchAppStatus={fetchAppStatus}
                    addApp={addApp}
                    saveApp={saveApp}
                  />
                ))
              )}
              <div ref={appsEndRef} />
            </div>
            <ApplicationAddCard
              addApp={addApp}
              fetchAppStatus={fetchAppStatus}
              inputsData={inputsData}
              btnText="Add Application"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.Applications;
  return { data };
};

const mapDispatchToProps = {
  dispatchGetApplications: getApplications,
  dispatchAddApplication: addApplication,
  dispatchDeleteApplication: deleteApplication,
  dispatchUpdateApplication: updateApplication,
};

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddApp));
