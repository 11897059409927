import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormGroup } from 'reactstrap';
import { useSelector } from 'react-redux';
import Button from '../Button/Button';
import apiClient from '../../gears/apiClient';

const domainSchema = Yup.object().shape({
  domain: Yup.string().matches(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/, 'Invalid domain name').required('Required'),
});

const DomainEnrollment = () => {
  const [isSend, setIsSend] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const companyDetails = useSelector((state) => state.Company.companyDetails);

  const companyDetailsRequest = async (domain) => {
    try {
      await apiClient.get('/company_full', domain);
      setIsSend(true);
    } catch (error) {
        setErrorMessage(error.message);
        if (error.response.status === 302) {
          error.message = 'web_test_disabled';
        }
    }
  };

  return (
    <Formik
      initialValues={{ domain: companyDetails.domain || '' }}
      validationSchema={domainSchema}
      onSubmit={(values) => { companyDetailsRequest(values.domain); }}
      enableReinitialize
    >
      {({ errors, touched, isValid, handleChange }) => (
        <div className="enrollusers-tab">
          <div className="title">Domain Enrollment for Google Admin Deployments</div>
          <div className="subtitle">
            If  using Google Admin to deploy the HubbleIQ Extension to end users, specify your organizational domain below (ie @mycompany.com).
            This will allow HubbleIQ to auto-enroll end users into your system.
          </div>
          <div className="onboarding-form">
            <Form id="domain-enroll-form">
              <FormGroup>
                <Field
                  id="invite-domain"
                  className="form-control"
                  name="domain"
                  placeholder="eg: @mycompany.com"
                  onChange={(e) => handleChange(e)}
                />
                {errors.domain && touched.domain ? (
                  <div className="error">{errors.domain}</div>
                ) : null}
                {errorMessage && <div>{errorMessage}</div>}
              </FormGroup>
              <div className="form-actions">
                {!isSend && (
                  <Button
                    type="submit"
                    generalType="default"
                    disabled={!isValid}
                  >
                    Submit Request
                  </Button>
                )}
                {isSend && (
                  <div className="text">
                    Thank you! You will be notified via email once the request as been approved.
                    <div>
                      <a href="mailto: contact@hubbleiq.com" className="link">Contact us</a> if you need assistance.
                    </div>
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};

  export default DomainEnrollment;
