import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { FETCH_USERS, USER_INVITE_REQUEST, USER_TEST_REQUEST, ADMIN_INVITE_REQUEST, USERS_LIST_INVITE_REQUEST, DELETE_USER } from './actionTypes';

import {
  fetchUsersApiError,
  fetchUsersSuccess,
  inviteUserError,
  inviteAdminError,
  requestTestError,
  inviteUsersListError,
  deleteUserSuccess, deleteUserError,

} from './actions';
import apiClient from '../../gears/apiClient';

function* fetchUsers({ payload: { data} }) {
  try {
    const response = yield apiClient.get('/users', data);
    yield put(fetchUsersSuccess(response));
  } catch (error) {
    yield put(fetchUsersApiError(error));
  }
}

export function* watchUsersFetch() {
  yield takeEvery(FETCH_USERS, fetchUsers);
}

function* inviteUser({ payload, callback }) {
  try {
    const response = yield apiClient.get('/invite-company-user', payload);
    if (response.error) {
      yield put(inviteUserError(response.error));
      toastr.error('Request failed.', response.error);
    } else {
      if (callback) {
        callback();
      }
      toastr.success('Invite sent.');
    }
  } catch (error) {
    yield put(inviteUserError(error));
    toastr.error('System', error);
  }
}

function* inviteAdmin({ payload, callback }) {
  try {
    const response = yield apiClient.get('/invite-admin', payload);
    if (response.error) {
      yield put(inviteAdminError(response.error));
      toastr.error('Request failed.', response.error);
    } else {
      if (callback) {
        callback();
      }
      toastr.success('Invite sent.');
    }
  } catch (error) {
    yield put(inviteAdminError(error));
    toastr.error('System', error);
  }
}

function* testRequest({ payload, callback }) {
  try {
    const response = yield apiClient.get('/request-test-run', payload);
    if (response.error) {
      yield put(requestTestError(response.error));
      toastr.error('Request failed.', response.error);
    } else {
      if (callback) {
        callback();
      }
      toastr.success('Test Request Sent.');
    }
  } catch (error) {
    yield put(requestTestError(error));
    toastr.error('System', error);
  }
}

function* inviteUsersList({ payload, callback }) {
  const formData = new FormData();
  formData.append('import', payload);
  try {
    yield apiClient.post('/add-bulk-user', formData);
      if (callback) {
        callback();
      }
      toastr.success('Invite sent.');
  } catch (error) {
    yield put(inviteUsersListError(error));
    toastr.error('System', error);
  }
}

function* deleteUser({id}) {
  try {
    yield apiClient.delete(`/user/${id}`);
    yield put(deleteUserSuccess(id));
  } catch (error) {
    yield put(deleteUserError(error));
    toastr.error('System', error);
  }
}

export function* inviteUserWatcher() {
  yield takeEvery(USER_INVITE_REQUEST, inviteUser);
}

export function* inviteAdminWatcher() {
  yield takeEvery(ADMIN_INVITE_REQUEST, inviteAdmin);
}

export function* requestTestWatcher() {
  yield takeEvery(USER_TEST_REQUEST, testRequest);
}

export function* inviteUsersListWatcher() {
  yield takeEvery(USERS_LIST_INVITE_REQUEST, inviteUsersList);
}

export function* deleteUsersWatcher() {
  yield takeEvery(DELETE_USER, deleteUser);
}

function* UsersSaga() {
  yield all([
    fork(watchUsersFetch),
    fork(inviteUserWatcher),
    fork(inviteAdminWatcher),
    fork(requestTestWatcher),
    fork(inviteUsersListWatcher),
    fork(deleteUsersWatcher),
  ]);
}

export default UsersSaga;
