import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import ArrowUp from '../../../images/icon-arrow-up.svg';
import NetworkStability from '../../../images/icon-network-stability.svg';
import Latency from '../../../images/icon_gauge.svg';
import PacketLoss from '../../../images/icon_packet_loss.svg';

const InternetPerformance = ({
  displayTitle = true,
  download,
  upload,
  jitter,
  latency,
  packetLoss,
  statusDescription,
}) => {
  const getHighlightedClass = (option) => {
    const statusesMap = {
      download: {
        decent_download: 'decent',
        low_download: 'bad',
      },
      upload: {
        decent_upload: 'decent',
        low_upload: 'bad',
      },
      jitter: {
        decent_jitter: 'decent',
        high_jitter: 'bad',
      },
      latency: {
        decent_latency: 'decent',
        high_latency: 'bad',
      },
      packetLoss: {
        decent_packetloss: 'decent',
        high_packetloss: 'bad',
      },
    };
    return statusesMap[option][statusDescription] ? statusesMap[option][statusDescription] : '';
  };

  const itemsArr = [
    {
      title: 'Upload',
      icon: ArrowUp,
      value: <>{upload}<span>mbps</span></>,
    },
    {
      title: 'Download',
      icon: ArrowUp,
      value: <>{download}<span>mbps</span></>,
    },
    {
      title: 'Jitter',
      icon: NetworkStability,
      value: <>{jitter}<span>ms</span></>,
    },
    {
      title: 'Latency',
      icon: Latency,
      value: <>{latency}<span>ms</span></>,
    },
    {
      title: 'Packet Loss',
      icon: PacketLoss,
      value: <>{packetLoss}<span>%</span></>,
    },
  ];

  const renderItem = () => {
    const result = itemsArr.map((item) => {
      let colorByTitle = item.title?.toLowerCase();
      const isPakcetLoss = colorByTitle === 'packet loss';
      const isDownload = colorByTitle === 'download';
      if (isPakcetLoss) {
        colorByTitle = 'packetLoss';
          if (packetLoss === null || packetLoss === undefined) {
            return null;
          }
      }
      return (
        <Col xs="12" sm="6" md="6" lg="6" xl="6" key={item.title}>
          <div className="item">
            <div className={`item-icon${isDownload ? ' rotate-180' : ''}`}>
              <img src={item.icon} alt={`icon-${item.title}`} />
            </div>
            <div className="item-info">
              <div className="item-title">{item.title}</div>
              <div className={`item-value ${getHighlightedClass(colorByTitle)}`}>{item.value}</div>
            </div>
          </div>
        </Col>
      );
    });
    return result;
  };

  return (
    <div className="internet-performance">
      {displayTitle && (<div className="internet-performance-label">Internet Performance</div>)}
      <Row>
        {renderItem()}
      </Row>
    </div>
  );
};

InternetPerformance.propTypes = {
  download: PropTypes.number,
  upload: PropTypes.number,
  jitter: PropTypes.number,
  latency: PropTypes.number,
  packetLoss: PropTypes.number,
  statusDescription: PropTypes.string,
};

export default InternetPerformance;
