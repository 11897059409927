import {takeEvery, put, all, fork} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import { GET_MY_PROFILE_REQUEST, UPDATE_MY_PROFILE_REQUEST } from './actionTypes';
import { getMyProfileSuccess, getMyProfileError, updateMyProfileSuccess, updateMyProfileError } from './actions';
import apiClient from '../../gears/apiClient';

const redirectToLogin = (err) => {
  if (err.statusCode === 401 || err.statusCode === 403) {
    window.location.href = '/admin/login';
  }
};

function* getMyProfile({callback, history}) {
  try {
    const response = yield apiClient.get('/users/me');
    yield put(getMyProfileSuccess(response));

      if (callback) {
        callback();
      }
  } catch (error) {
    yield put(getMyProfileError(error));
    toastr.error('System', error.message);
    redirectToLogin(error, history);
  }
}

export function* getMyProfileWatcher() {
  yield takeEvery(GET_MY_PROFILE_REQUEST, getMyProfile);
}

function* updateMyProfile({payload, callback}) {
  try {
    yield apiClient.put('/update-me', payload);
    yield put(updateMyProfileSuccess());

    if (callback) {
      // callback();
      toastr.success('System', 'Success profile update');
    }
  } catch (error) {
    yield put(updateMyProfileError(error));
    toastr.error('System', error);
  }
}

export function* updateMyProfileWatcher() {
  yield takeEvery(UPDATE_MY_PROFILE_REQUEST, updateMyProfile);
}

function* ProfileSaga() {
  yield all([fork(getMyProfileWatcher), fork(updateMyProfileWatcher)]);
}

export default ProfileSaga;
