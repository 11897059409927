import React, {Component} from 'react';
import {Card, CardBody, Col, Row, TabContent, TabPane} from 'reactstrap';
import {connect} from 'react-redux';
import {isEmpty, uniqWith} from 'lodash';
import {changeBackButton, fetchTestDetails, fetchRecentActivities} from '../../store/actions';
import GoogleMap from '../../components/google-map';
import UserDetailsTabs from '../../components/UserDetailsTabs';
import ChartBlocks from '../../components/ChartBlocks';
import Last30DaysTestChart from '../../components/charts/last-30-days-test';
import AhoyTrack from '../../modules/AhoyTrack';

class IqOverview extends Component {
  constructor(props) {
    AhoyTrack();
    super(props);
    this.state = {
      filter: null,
      sortBy: null,
      tooltipOpen1: false,
      activeTab: '0',
      // dateRangeValue: 24,
    };
  }

  componentDidMount() {
    const {fetchRecentActivities, history} = this.props;
    const {data} = this.props;
    fetchRecentActivities(history);
    this.fetchData();
    if (data?.user_activities?.[0]?.test_id) {
      this.fetchTestDetailsResult();
    }
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (data?.user_activities?.[0]?.test_id !== prevProps?.data?.user_activities?.[0]?.test_id) {
      if (data?.user_activities?.[0]?.test_id) {
        this.fetchTestDetailsResult();
      }
    }
  }

  onMarkerClick = (id) => {
    const {history} = this.props;
    history.push(`/admin/user-status/${id}`);
  }

  navigateToPage = (page) => {
    this.fetchData(page);
  }

  onTableSortChange = (sortBy) => {
    if (sortBy) {
      this.setState({
        sortBy,
      }, () => this.fetchData());
    } else {
      this.setState({
        sortBy: null,
      }, () => this.fetchData());
    }
  }

  onFilterChange = (filter) => {
    this.setState({filter}, () => this.fetchData());
  }

  fetchData = (page) => {
    const {filter, sortBy} = this.state;
    let queryParams = {};

    if (page) {
      queryParams.page = page;
    }

    if (sortBy) {
      queryParams.sort_by = sortBy;
    }

    if (filter) {
      queryParams = {...queryParams, ...filter};
    }
  }

  getMapCenter = () => {
    const {mapMarkers} = this.props;
    if (mapMarkers.length <= 0) {
      return undefined;
    }

    return {lat: mapMarkers[0].lat, lng: mapMarkers[0].lng};
  }

  toggle = (id) => {
    const { tooltipOpen1 } = this.state;
    switch (id) {
      case 1:
        this.setState({tooltipOpen1: !tooltipOpen1});
        break;
    }
  };

  fetchTestDetailsResult = () => {
    const { data, history, fetchTestDetails } = this.props;
    const sortedByDate = data?.user_activities?.sort((a, b) => new Date(b.session_date) - new Date(a.session_date));
    fetchTestDetails(sortedByDate[0]?.test_id, history);
  };

  toggleTabs = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const {loading, mapMarkers } = this.props;
    const { activeTab, tooltipOpen1 } = this.state;

    // const options = [
    //   {
    //     value: 24,
    //     label: 'Today',
    //   },
    //   {
    //     value: 3,
    //     label: '3 days',
    //   },
    //   {
    //     value: 7,
    //     label: '7 days',
    //   },
    //   {
    //     value: 14,
    //     label: '14 days',
    //   },
    // ];

    const tabsArr = ['Org Dashboard', 'Network', 'Web traffic & security', 'Applications', 'Survey'];
    const staticValues = [
      {
        activities_number: 2,
        date: '06/31/2021',
      },
      {
        activities_number: 4,
        date: '06/31/2021',
      },
      {
        activities_number: 7,
        date: '07/31/2021',
      },
      {
        activities_number: 1,
        date: '08/31/2021',
      },
      {
        activities_number: 5,
        date: '09/01/2021',
      },
    ];

    return (
      <div className="container-fluid content user-details iqoverview">
        <Row>
          <Col>
            <div className="user-details-top">
              <div className="user-details-tabs">
                <UserDetailsTabs activeTab={activeTab} toggleTabs={this.toggleTabs} tabs={tabsArr} />
              </div>
            </div>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="0">

                {/* <div className="card-top card-top_custom">
                  <div className="card-top-title">
                    <h4 className="card-title font-weight-normal">Connectivity Experience</h4>
                    <div className="card-date-range">
                      <Input type="select">
                        {options.map((option, index) => (
                          <option key={index} value={option.value}>{option.label}</option>
                        ))}
                      </Input>
                    </div>
                    <span>
                      <i id="ce" className="mdi mdi-help-circle-outline text-primary ml-2" />
                      <Tooltip placement="right" isOpen={tooltipOpen} target="ce" toggle={this.toggle}>
                        These gauges show a rollup of this user’s overall internet experience over this time period
                      </Tooltip>
                    </span>
                  </div>
                </div> */}

                <div className="tab-title">
                  <h4 className="card-title">Organizational Dashboard</h4>
                </div>

                <div className="tab-block">
                  <ChartBlocks toggle={this.toggle} tooltipOpen={tooltipOpen1} />
                </div>

                <div className="tab-block">
                  <Row className="mb-4">
                    <Col>
                      <div className="recent-tests-card">
                        <Last30DaysTestChart dataSet={staticValues} title="IQ Experience Over Time" />
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="tab-block">
                  <Row>
                    <Col className="col-card">
                      <Card className="card-map">
                        <CardBody>
                          <div className="d-flex justify-between align-items-center mb-4">
                            <div className="d-flex flex-column justify-content-center">
                              <div className="card-title mb-0">Overview</div>
                            </div>
                          </div>
                          <div id="usa" className="vector-map-height map-background">
                            {
                              !loading && (
                                <GoogleMap
                                  markers={mapMarkers}
                                  center={this.getMapCenter()}
                                  zoom={mapMarkers.length > 0 ? 9 : 0}
                                  height="360px"
                                  onMarkerClick={this.onMarkerClick}
                                />
                              )
                            }
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </TabPane>

              <TabPane tabId="1" />
              <TabPane tabId="2" />
              <TabPane tabId="3" />
              <TabPane tabId="4" />
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {error, loading, data} = state.UserStatus;
  const {data: activitiesData} = state.RecentActivities;
  let mapMarkers = [];

  if (!isEmpty(activitiesData)) {
    mapMarkers = uniqWith(activitiesData?.reduce((obj, item) => {
      obj.push({
        lat: +item.t_latitude,
        lng: +item.t_longitude,
        name: `${item.city}, ${item.region_name}`,
        status: item.test_result,
        user_id: item.user_id,
      });
      return obj;
    }, []), (a, b) => {
      return a.lat === b.lat && a.lng === b.lng;
    });
  }

  return {
    error,
    loading,
    data,
    mapMarkers,
    activitiesData: isEmpty(activitiesData) ? [] : activitiesData,
  };
};

export default connect(mapStateToProps, {
  changeBackButton,
  fetchTestDetails,
  fetchRecentActivities,
})(IqOverview);
