import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import queryString from 'query-string';
import RefreshButton from '../../components/refresh-button';
import Pagination from '../../components/pagination';
import DeviceEventsTable from '../../components/tables/DeviceEventsTable';
import DeviceEventsTableFilter from '../../components/tables-filters/DeviceEventsTableFilter';
import { buildPaginationData } from '../../utility/utils';
import { getDeviceActivities } from "../../store/device-activities/actions";

const DeviceEvents = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {deviceActivities, loading} = useSelector((state) => state.Device);
  const [filter, setFilter] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const fetchData = useCallback((page = 1) => {
    let queryParams = { type: 'device' };
    
    if (page) {
      queryParams.page = page;
    }
    
    if (sortBy) {
      queryParams.sort_by = sortBy;
    }
    
    if (filter) {
      queryParams = { ...queryParams, ...filter };
    }
    
    dispatch(getDeviceActivities(history, queryParams));
  }, [sortBy, filter, history, dispatch, getDeviceActivities]);
  
  useEffect(() => {
    const { filter_by } = queryString.parse(location.search);
    
    if (filter_by) {
      setFilter({ filter_by });
      fetchData();
    } else {
      fetchData();
    }
  }, [location.search, fetchData]);
  const navigateToPage = (page) => {
    fetchData(page);
  };
  
  const onTableSortChange = (sortBy) => {
    if (sortBy) {
      setSortBy(sortBy);
      fetchData();
    }
  };
  
  const onFilterChange = (filter) => {
    history.replace({ search: '' });
    setFilter(filter);
    fetchData();
  };
  
  useEffect(() => {
    if (deviceActivities?.activities?.length > 0) {
      setPaginationData(buildPaginationData(deviceActivities));
    }
  }, [deviceActivities]);

  return (
    <div className="container-fluid content">
      <Row className="page-title align-items-center">
        <Col sm={6}>
          <div className="page-title-box">
            <h2>Device Events</h2>
          </div>
        </Col>
      </Row>
      
      <Row className="align-items-center">
        <Col xl={12}>
          <div className="justify-between align-items-center mb-4 d-md-flex">
            <div className="align-items-center d-md-flex">
              <div className="d-flex align-items-center mr-5">
                <span className="card-title mb-0">Recent Sessions</span>
                <RefreshButton onRefresh={() => fetchData()} />
              </div>
            </div>
          </div>
          <div className="justify-between mb-4 d-md-flex">
            <DeviceEventsTableFilter onFilterChange={onFilterChange} />
          </div>
          <div className="table-responsive">
            <DeviceEventsTable
              fetch={fetchData}
              data={deviceActivities?.activities}
              isLoading={loading}
              onSortChange={onTableSortChange}
            />
          </div>
          {(deviceActivities?.activities?.length > 0 && paginationData) && <Pagination paginationData={paginationData} navigateTo={navigateToPage} />}
        </Col>
      </Row>
    </div>
  );
};

export default DeviceEvents;
