import React from 'react'
import IconDevice from '../../images/hops/icon-device.svg'
import IconWifi from '../../images/hops/icon-wifi.svg'
import IconWan from '../../images/hops/icon-wan.svg'
import IconCloud from '../../images/hops/icon-cloud.svg'
import AveragesBlock from './AveragesBlock'
import style from './Averages.module.css'

const Averages = ({hops, valueClick, onValueClick}) => {
  return (
    <div className={style.averages}>
        <AveragesBlock
          icon={IconDevice}
          label="Endpoint device"
          value={hops?.deviceLan?.average}
          valueClick={valueClick}
          onValueClick={onValueClick}
        />
        <AveragesBlock
          icon={IconWifi}
          label="LAN gateway"
          value={hops?.lanWan?.average}
          valueClick={valueClick}
          onValueClick={onValueClick}
        />
        <AveragesBlock
          icon={IconWan}
          label="ISP WAN"
          value={hops?.wanCloud?.average}
          valueClick={valueClick}
          onValueClick={onValueClick}
        />
        <AveragesBlock
          icon={IconCloud}
          label="Web Application"
        />
    </div>
  )
}

export default Averages
