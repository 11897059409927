import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ReduxToastr from '../../utility/redux-toastr';
import TopBar from './TopBar';
import InviteAdminForm from '../../forms/InviteAdminForm';
import { InviteModalProvider } from '../../context/Context';
import RequestTestForm from '../../forms/RequestTest';
import InviteModalBody from '../InviteModalBody';
import ProSidebar from './ProSidebar';
import HubbleLogo from '../../images/HubbleIQ_logo.png';
import Onboarding from '../onboarding/Onboarding';
import Trial from './Trial';
import OnBoardingTooltip from "../onboarding/OnBoardingTooltip/OnBoardingTooltip";
import apiClient from "../../gears/apiClient";
import {extensionLink, installExtensionLink} from "../../helpers/page_helper";

const Layout = ({
    children,
  }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inviteModalContent, setInviteModalContent] = useState(false);
  const [inviteAdminModalContent, setInviteAdminModalContent] = useState(false);
  const [testModalContent, setTestModalContent] = useState(false);
  const [contactUsContent, setContactUsContent] = useState(false);
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const [showOnBoardingTooltip, setShowOnBoardingTooltip] = useState(true);
  const [installExtensionLink, setInstallExtensionLink] = useState('');
  const [trialClosed, setTrialClosed] = useState(localStorage.getItem('trialClosed') === 'true');
  const { pathname } = useLocation();
  const { trial, trial_expiration } = useSelector((state) => state.Company);
  const { showRightSidebar: isSidebarOpen } = useSelector((state) => state.Layout);
  const { data, loading: profileLoading } = useSelector((state) => state.Profile);
  const { onboarding_step: onBoardingStep } = data || {};
  
  const closeTooltip = () => {
    setShowOnBoardingTooltip(false);
  }
  
  const fetchInstallExtensionLink = async () => {
    try {
      const response = await apiClient.get('/invite-company-user');
      setInstallExtensionLink(`${process.env.REACT_APP_INSTALL_EXTENSION_LINK || extensionLink}${response?.invite_token}`);
    } catch (error) {
      console.error('Error fetching install extension link:', error);
    }
  };
  
  const toggleModal = (text) => {
    setIsModalOpen(!isModalOpen);
    switch (text) {
      case 'install':
        fetchInstallExtensionLink();
        setIsModalOpen(false);
        break;
      case 'invite':
        setInviteModalContent(true);
        setInviteAdminModalContent(false);
        setTestModalContent(false);
        setContactUsContent(false);
        break;
      case 'admin':
        setInviteModalContent(false);
        setInviteAdminModalContent(true);
        setTestModalContent(false);
        setContactUsContent(false);
        break;
      case 'test':
        setInviteModalContent(false);
        setInviteAdminModalContent(false);
        setTestModalContent(true);
        setContactUsContent(false);
        break;
      case 'contact':
        setInviteModalContent(false);
        setInviteAdminModalContent(false);
        setTestModalContent(false);
        setContactUsContent(true);
        break;
      default:
        break;
    }
  };
  
  useEffect(() => {
    if (installExtensionLink) {
      window.open(installExtensionLink, '_blank');
    }
  }, [installExtensionLink]);
  
  const close = () => {
    toggleModal();
  };
  const trialClose = () => {
    setTrialClosed(true);
    localStorage.setItem('trialClosed', 'true');
  };
  const closeOnboardingModal = () => {
    setOnboardingModalOpen(false);
  };
  const openOnboardingModal = () => {
    setOnboardingModalOpen(true);
  };
  
  const onBoardingToolTipLocation = pathname.includes('admin/user-status') || pathname.includes('admin/broadband-insights');
  
  useEffect(() => {
    if ((onBoardingStep && onBoardingStep < 3) && onBoardingToolTipLocation) {
      setShowOnBoardingTooltip(true);
    } else {
      setShowOnBoardingTooltip(false);
    }
  }, [onBoardingStep, onBoardingToolTipLocation]);
  return (
    <>
      <div id="layout-wrapper">
        {trial && !trialClosed && (
          <Trial
            trialExpiration={trial_expiration}
            trialClose={trialClose}
            mainContact={data?.main_contact_user}
            toggleModal={toggleModal}
          />
        )}
        <TopBar
          toggleModal={toggleModal}
          openOnboardingModal={openOnboardingModal}
          trial={trial}
          isTrialClosed={trialClosed}
          isSidebarOpen={isSidebarOpen}
        />
        <div className="main-content">
          <div className={`page-content ${pathname === '/admin/extension-settings' ? 'ext-configuration' : ''}${trial ? 'page-content_padding' : ''}`}>
            <InviteModalProvider value={toggleModal}>
              <ProSidebar
                toggleModal={toggleModal}
                openOnboardingModal={openOnboardingModal}
                trial={trial}
                isTrialClosed={trialClosed}
              />
              {showOnBoardingTooltip && (
                <div
                  className="user-details-top-onboarding"
                  style={{ top: trial && !trialClosed ? '120px' : '75px' }}
                >
                  <OnBoardingTooltip
                    onClose={closeTooltip}
                    toggleModal={toggleModal}
                  />
                </div>
              )}
              <main className={`w-100 ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'} ${window.location.pathname.includes('/dashboard') && 'dashboard_bg'}`}>
                {children}
              </main>
            </InviteModalProvider>
          </div>
        </div>
        <ReduxToastr />
        <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg" className="custom">
          <ModalHeader toggle={toggleModal}>
            {inviteModalContent && 'Invite user'}
            {inviteAdminModalContent && 'Invite admin'}
            {testModalContent && 'Request test'}
            {contactUsContent && 'Contact the main billing contact for your company'}
          </ModalHeader>
          <ModalBody>
            {inviteModalContent && <InviteModalBody close={close} />}
            {inviteAdminModalContent && <InviteAdminForm close={close} isLabelVisible={false} />}
            {testModalContent && <RequestTestForm close={close} isLabelVisible={false} />}
            {contactUsContent && (
              <div>
                <div className="modal-text">
                  To subscribe to HubbleIQ, please contact the original user who signed up for the free trial.
                  If you’d like to be assigned as the main billing contact for your HubbleIQ instance,
                  please <a className="link" href="mailto:contact@hubbleiq.com">contact us</a>.
                </div>
              </div>
            )}
          </ModalBody>
        </Modal>
        
        {data && onboardingModalOpen && (
          <Modal isOpen={onboardingModalOpen} size="onboarding" toggle={closeOnboardingModal}>
            <ModalHeader toggle={closeOnboardingModal}>
              <span className="modal-logo">
                <img src={HubbleLogo} alt="logo" className="img-responsive" />
              </span>
            </ModalHeader>
            <ModalBody>
              <Onboarding closeOnboardingModal={closeOnboardingModal} adminRole={data?.role} loading={profileLoading} />
            </ModalBody>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Layout;
