import React, {useMemo, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import ReactTable from '../ReactTable';
import {logsNetworkTableColumns} from "../../helpers/tabs_helper";
import IssueDetailsDrawer from "../drawers/IssueDetailsDrawer/IssueDetailsDrawer";

const NetworkEventsTable = ({ data, isLoading, onSortChange, disableSortBy = false}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [testId, setTestId] = useState(null);

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
    setTestId(null);
  };

  const onDrawerOpen = (id) => {
    setTestId(id);
    setIsDrawerOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: logsNetworkTableColumns.USER_NAME.header,
        accessor: logsNetworkTableColumns.USER_NAME.accessor,
      },
      {
        Header: logsNetworkTableColumns.TYPE.header,
        accessor: logsNetworkTableColumns.TYPE.accessor,
      },
      {
        Header: logsNetworkTableColumns.START_TIME.header,
        accessor: logsNetworkTableColumns.START_TIME.accessor,
        Cell: ({ row: { original }}) => (
          original.startTime || 'N/A'
        ),
      },
      {
        Header: logsNetworkTableColumns.END_TIME.header,
        accessor: logsNetworkTableColumns.END_TIME.accessor,
        Cell: ({ row: { original }}) => (
          original.endTime || 'N/A'
        ),
      },
      {
        Header: logsNetworkTableColumns.DURATION.header,
        accessor: logsNetworkTableColumns.DURATION.accessor,
        Cell: ({ row: { original }}) => original?.duration || 'N/A',
      },
      {
        Header: logsNetworkTableColumns.COUNTRY.header,
        accessor: logsNetworkTableColumns.COUNTRY.accessor,
      },
      {
        Header: logsNetworkTableColumns.ISP.header,
        accessor: logsNetworkTableColumns.ISP.accessor,
      },
      {
        Header: logsNetworkTableColumns.GUID.header,
        accessor: logsNetworkTableColumns.GUID.accessor,
        Cell: ({ row: { original }}) => original.guid || 'N/A',
      },
    ], [],
  );

    const onSort = useCallback(({ sortBy }) => {
      if (onSortChange) {
        if (sortBy.length > 0) {
          const [{id, desc}] = sortBy;
          onSortChange(`${[id]} ${desc ? 'desc' : 'asc'}`);
        } else {
          onSortChange();
        }
      }
  }, []);
  
  // This functionality will be implemented in the next Sprint.
  // const handleRowClick = (rowData) => {
  //   onDrawerOpen(rowData.id);
  // };
  return (
    <>
      <ReactTable
        columns={columns}
        data={data}
        onSortChange={onSort}
        loading={isLoading}
        manualSortBy
        disableMultiSort
        disableSortBy={disableSortBy}
      />
      {/*<IssueDetailsDrawer*/}
      {/*  isOpen={isDrawerOpen}*/}
      {/*  onRowClick={handleRowClick}*/}
      {/*  onHandlerClose={onDrawerClose}*/}
      {/*  issueId={testId}*/}
      {/*/>*/}
    </>
  );
};

NetworkEventsTable.defaultProps = {
  data: [],
  isLoading: false,
};

NetworkEventsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  onSortChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default withRouter(NetworkEventsTable);
