import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';
import { fetchAnalytics } from '../../store/actions';
import DashboardAppCard from './DashboardAppCard';

const DashboardAplications = () => {
  const {
    applications_performance_summary: applications,
    applications_performance_summary_isLoading: applicationsIsLoading,
  } = useSelector((state) => state.DashboardAnalytics);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAnalytics('applications_performance_summary'));
  }, []);

  return (
    <>
      {applicationsIsLoading && <div className="spinner-wrapper"><Spinner color="primary" /></div>}
      <Row>
        {applications?.map((app, i) => (
          <Col lg="4" md="6" className="mb-3" key={i}>
            <DashboardAppCard app={app} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default DashboardAplications;
