import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import { logsTabsArr, renderTabs } from '../../helpers/tabs_helper';

const Logs = () => {
  const [activeTab, setActiveTab] = useState('1');

  return (
    <div className="container-fluid content">
      <Row className="page-title align-items-center">
        <Col sm={6}>
          <div className="page-title-box">
            <h2>Logs</h2>
          </div>
        </Col>
      </Row>
      <div className="settings-customization recent-tests-card">
        {renderTabs(logsTabsArr, activeTab, setActiveTab)}
      </div>
    </div>
  )
}

export default Logs