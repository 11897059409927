import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from '../ReactTable';
import { logsApplicationTableColumns } from "../../helpers/tabs_helper";

const ApplicationsEventsTable = ({data, isLoading, onSortChange, disableSortBy = false}) => {
  const statusColor = (statusCode) => {
    if (statusCode >= 100 && statusCode <= 299) {
      return 'result-success';
    }
    if (statusCode >= 300 && statusCode <= 399) {
      return 'result-warning';
    }
    return 'result-danger';
  };

  const appStatus = (status, responseCode) => {
    switch (status) {
      case true:
        return (
          <span className={`result-status ${responseCode ? statusColor(responseCode) : 'result-success'}`}>
            Reachable{responseCode ? `, ${responseCode}` : ''}
          </span>
        );
      default:
        return (
          <span className={`result-status ${responseCode ? statusColor(responseCode) : 'result-danger'}`}>
            Not Reachable{responseCode ? `, ${responseCode}` : ''}
          </span>
        );
    }
  };

  const transformUrl = (url) => {
    const hasProtocol = /^http[s]?:\/\//.test(url);
    const modifiedUrl = hasProtocol ? url : `http://${url}`;
    return modifiedUrl;
  };

  const columns = [
      {
        Header: logsApplicationTableColumns.CREATED_AT.header,
        accessor: logsApplicationTableColumns.CREATED_AT.accessor,
      },
      {
        Header: logsApplicationTableColumns.NAME.header,
        accessor: logsApplicationTableColumns.NAME.accessor,
      },
      {
        Header: logsApplicationTableColumns.APPLICATION.header,
        accessor: logsApplicationTableColumns.APPLICATION.accessor,
      },
      {
        Header: logsApplicationTableColumns.STATUS.header,
        accessor: logsApplicationTableColumns.STATUS.accessor,
        Cell: ({row: {original}}) => {
          return original?.status ? 'Reachable' : 'Not Reachable';
        },
      },
      {
        Header: logsApplicationTableColumns.AVG_RESPONSE_TIME.Header,
        accessor: logsApplicationTableColumns.AVG_RESPONSE_TIME.accessor,
        Cell: ({row: {original}}) => original?.response_time || 'N/A',
      },
      {
        Header: logsApplicationTableColumns.AVG_PAGE_LOAD.Header,
        accessor: logsApplicationTableColumns.AVG_PAGE_LOAD.accessor,
        Cell: ({row: {original}}) => {
          return original?.avg_page_load_time || 'N/A'
        },
      },
      {
        Header: logsApplicationTableColumns.URL.header,
        accessor: logsApplicationTableColumns.URL.accessor,
        disableSortBy: true,
        Cell: ({row: {original}}) => {
          return <a href={transformUrl(original?.url)} target="_blank" rel="noreferrer">{original?.url}</a>;
        }
      },
    ];

  const onSort = useCallback(({sortBy}) => {
    if (onSortChange) {
      if (sortBy.length > 0) {
        const [{id, desc}] = sortBy;
        onSortChange(`${[id]} ${desc ? 'desc' : 'asc'}`);
      } else {
        onSortChange();
      }
    }
  }, []);

  return (
    <>
      <ReactTable
        columns={columns}
        data={data}
        onSortChange={onSort}
        loading={isLoading}
        manualSortBy
        disableMultiSort
        disableSortBy={disableSortBy}
      />
    </>

  );
};

ApplicationsEventsTable.defaultProps = {
  data: [],
  isLoading: false,
};

ApplicationsEventsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onSortChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { error, loading } = state.Applications;
  return { error, loading };
};

export default withRouter(connect(mapStateToProps, {})(ApplicationsEventsTable));
