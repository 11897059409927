import React, { useEffect, useState } from 'react';
import {Col, Input, FormGroup, Label, Row} from 'reactstrap';
import { useFormik } from 'formik';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import * as Yup from 'yup';
import { getHelpdeskMessage, updateHelpdeskMessage } from '../../store/actions';
import Button from '../Button/Button';
import ConfirmationModal from '../ConfirmationModal';
import ExtensionConfigurationFormItem from './ExtensionConfigurationFormItem';

// eslint-disable-next-line no-useless-escape
const URL = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

const validationSchema = Yup.object().shape({
  trigger_url: Yup.string().when('isTriggerUrlEnabled', {
    is: true,
    then: Yup.string().matches(URL, 'URL is invalid').required(),
    otherwise: Yup.string(),
  }),
});

const ExtensionConfigurationForm = ({
  dispatchGetHelpdeskMessage,
  dispatchUpdateHelpdeskMessage,
  isHelpdeskMessageLoading,
  helpdeskMessage,
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  useEffect(() => {
    dispatchGetHelpdeskMessage();
  }, []);

  const formik = useFormik({
    initialValues: {
      chrome_new_connection_auto_test: helpdeskMessage?.chrome_new_connection_auto_test || false,
      chrome_service_outage_auto_test: helpdeskMessage?.chrome_service_outage_auto_test || false,
      chrome_detect_service_outage: helpdeskMessage?.chrome_detect_service_outage || false,
      chrome_daily_auto_test: helpdeskMessage?.chrome_daily_auto_test || false,
      chrome_enabled_notification: helpdeskMessage?.chrome_enabled_notification || false,
      chrome_enable_limited_notifications_only: helpdeskMessage?.chrome_enable_limited_notifications_only || false,
      trigger_url: helpdeskMessage?.trigger_url || '',
      isTriggerUrlEnabled: helpdeskMessage?.trigger_url?.length > 0,
      last_restart_time_threshold: helpdeskMessage?.last_restart_time_threshold || 0,
      test_frequency_minutes: helpdeskMessage?.test_frequency_minutes || 0,
      extension_notification: helpdeskMessage?.extension_notification || false,
      chrome_detect_instability: helpdeskMessage?.chrome_detect_instability || false,
      test_validation: helpdeskMessage?.test_validation || false,
      store_email: helpdeskMessage?.store_email || false,
    },
    validationSchema,
    onSubmit: (values) => {
      const modifiedValues = {...values};
      if (!values.isTriggerUrlEnabled) {
        delete modifiedValues.isTriggerUrlEnabled;
        modifiedValues.trigger_url = null;
        dispatchUpdateHelpdeskMessage(modifiedValues);
      } else {
        delete modifiedValues.isTriggerUrlEnabled;
        dispatchUpdateHelpdeskMessage(modifiedValues);
      }
    },
    enableReinitialize: true,
  });

  const pluralText = (value, text) => (value !== 1 ? `${text}s` : text);

  const onConfirmClick = () => {
    setIsConfirmModalOpen(false);
    formik.setFieldValue('store_email', false);
  };

  return (
    <div className="settings-tab-block">
      <div className="title">Global settings for the extension users</div>
      <div className="subtitle">
        Select the options below to configure the behavior and features for end users. These settings may take up to an hour to go into effect
      </div>
      <form className="form form-extension" onSubmit={formik.handleSubmit}>
        <Row>
          <Col xl="6">
            <div className="settings-tab-item">
              <Row>
                <Col md={7}>
                  <FormGroup tag="fieldset">
                    <ExtensionConfigurationFormItem
                      label="Automatically test new networks"
                      name="chrome_new_connection_auto_test"
                      value={formik.values.chrome_new_connection_auto_test}
                      action={formik.handleChange}
                      description="Automatically run a test when a new internet connection is detected"
                    />
                    <ExtensionConfigurationFormItem
                      label="Detect ISP Outages"
                      name="chrome_detect_service_outage"
                      value={formik.values.chrome_detect_service_outage}
                      action={formik.handleChange}
                      description="Monitor and track ISP interruptions from your end users"
                    />
                    <ExtensionConfigurationFormItem
                      label="Auto-test after a detected service outage"
                      name="chrome_service_outage_auto_test"
                      value={formik.values.chrome_service_outage_auto_test}
                      action={formik.handleChange}
                      description="Auto-test after a detected service outage."
                    />
                    <ExtensionConfigurationFormItem
                      label="Reocuring testing"
                      name="chrome_daily_auto_test"
                      value={formik.values.chrome_daily_auto_test}
                      action={formik.handleChange}
                      description="Triger a network test if the most recent results are over"
                      isDisabled
                    >
                      <>
                        <div className="inline-input">
                          {formik.values.chrome_daily_auto_test && (
                          <Input
                            type="number"
                            id="test_frequency_minutes"
                            name="test_frequency_minutes"
                            value={formik.values.test_frequency_minutes}
                            className={`form-control ${formik.errors.test_frequency_minutes ? 'is-invalid' : ''}`}
                            onChange={(e) => formik.handleChange(e)}
                            min="1"
                            disabled
                          />
                          )}
                        </div>
                        {`${pluralText(formik.values.test_frequency_minutes, 'minute')} old`}
                      </>
                    </ExtensionConfigurationFormItem>
                  </FormGroup>

                  <FormGroup tag="fieldset">
                    <ExtensionConfigurationFormItem
                      label="Enable Notifications"
                      name="chrome_enabled_notification"
                      value={formik.values.chrome_enabled_notification}
                      action={formik.handleChange}
                    />
                    <ExtensionConfigurationFormItem
                      label="Display Limited Notifications"
                      name="chrome_enable_limited_notifications_only"
                      value={formik.values.chrome_enable_limited_notifications_only}
                      action={formik.handleChange}
                      description="Only display a notification is the device is offline or an internet service interruption has been detected."
                    />
                    <ExtensionConfigurationFormItem
                      label="Device Restart Notification"
                      name="extension_notification"
                      value={formik.values.extension_notification}
                      action={formik.handleChange}
                      description="Notify user weekly if their device hasn&#39;t been restarted in over"
                    >
                      <>
                        <div className="inline-input">
                          {formik.values.extension_notification && (
                          <Input
                            type="number"
                            id="last_restart_time_threshold"
                            name="last_restart_time_threshold"
                            value={formik.values.last_restart_time_threshold}
                            className={`form-control ${formik.errors.last_restart_time_threshold ? 'is-invalid' : ''}`}
                            onChange={(e) => formik.handleChange(e)}
                            min="0"
                            max="99"
                          />
                          )}
                        </div>
                        {formik.values.last_restart_time_threshold !== 1 ? 'days' : 'day'}
                      </>
                    </ExtensionConfigurationFormItem>
                    <ExtensionConfigurationFormItem
                      label="Trigger URL"
                      name="isTriggerUrlEnabled"
                      value={formik.values.isTriggerUrlEnabled}
                      action={formik.handleChange}
                      description="Enter below the URL which will trigger the HubbleIQ extension to run."
                    >
                      <FormGroup>
                        <Label for="trigger_url">Trigger URL</Label>
                        {formik.values.isTriggerUrlEnabled && (
                          <Input
                            type="text"
                            id="trigger_url"
                            name="trigger_url"
                            value={formik.values.trigger_url}
                            className={`settings-trigger-input ${formik.errors.trigger_url ? 'is-invalid' : ''}`}
                            onChange={(e) => formik.handleChange(e)}
                            disabled={!formik.values.isTriggerUrlEnabled}
                          />
                        )}
                        {}
                      </FormGroup>
                    </ExtensionConfigurationFormItem>
                    <ExtensionConfigurationFormItem
                      label="Instability Detection"
                      name="chrome_detect_instability"
                      value={formik.values.chrome_detect_instability}
                      action={formik.handleChange}
                      description="Notify users when their network becomes too unstable to real time communication, such as video and voice calls. (Note: If this is set to false, do not notify use of instability alerts)"
                    />
                    <ExtensionConfigurationFormItem
                      label="Test validation"
                      name="test_validation"
                      value={formik.values.test_validation}
                      action={formik.handleChange}
                    />
                    <ExtensionConfigurationFormItem
                      label="Storing User Email"
                      name="store_email"
                      value={formik.values.store_email}
                      action={formik.handleChange}
                      setIsConfirmModalOpen={setIsConfirmModalOpen}
                      description="Only available for Extension V3"
                    />
                  </FormGroup>

                  <div className="form-btn d-flex justify-content-between">
                    <Button
                      generalType="default"
                      type="submit"
                      disabled={isHelpdeskMessageLoading}
                    >
                      Save changes
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </form>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        bodyText="Once you deactivate this settings all user emails will be removed."
        confirm={onConfirmClick}
        reject={() => setIsConfirmModalOpen(false)}
      />
    </div>
  );
};

ExtensionConfigurationForm.defaultProps = {
};

ExtensionConfigurationForm.propTypes = {
  dispatchUpdateHelpdeskMessage: PropTypes.func.isRequired,
  isHelpdeskMessageLoading: PropTypes.bool.isRequired,
  helpdeskMessage: PropTypes.shape({
    chrome_head_request: PropTypes.string,
    chrome_sub_head_request: PropTypes.string,
    support_email: PropTypes.string,
    custom_redirect_url: PropTypes.string,
  }),
};

const mapStateToProps = (state) => {
  const {isHelpdeskMessageLoading, helpdeskMessage = {}} = state.EXConfig;
  return {isHelpdeskMessageLoading, helpdeskMessage};
};

const mapDispatchToProps = {
  dispatchGetHelpdeskMessage: getHelpdeskMessage,
  dispatchUpdateHelpdeskMessage: updateHelpdeskMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionConfigurationForm);
