import React, {useState, useRef} from 'react';
import * as PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import SearchByColumn from './tables-filters/SearchByColumn';
import { mapDataToLegend } from '../helpers/page_helper';
import Button from './Button/Button';

const TableFilters = ({
  searchByColumnOptions,
  onFilterChange,
  userId,
  currentFilter,
  onlySelect = false,
  defaultSelect,
  legendSearchBy=null,
}) => {
  const [filter, setFilter] = useState({});
  const [showResetBtn, setShowResetBtn] = useState(false);
  const [isFilterReady, setIsFilterReady] = useState(false);
  const searchFilterRef = useRef();

  if (userId) {
    searchByColumnOptions.splice(0, 1);
  }

  const onSearchChange = (value) => {
    const {searchBy, keyword} = value;
    
    if (!keyword) {
      setIsFilterReady(false);
    } else {
      setIsFilterReady(true);
    }
    
    if(onlySelect) {
      if (searchBy) {
        setFilter({...filter, ...value});
      } else {
        const {searchBy, ...rest} = filter;
        setFilter({...rest});
      }
    } else {
      if (searchBy && keyword) {
        setFilter({...filter, ...value});
      } else {
        const {searchBy, keyword, ...rest} = filter;
        setFilter({...rest});
      }
    }
    

    if (searchBy) {
      setFilter({...filter, ...value});
    } else {
      const {searchBy, ...rest} = filter;
      setFilter({...rest});
    }
  };

  const handleSubmit = () => {
    
    const legend = {
      searchBy: legendSearchBy,
    };

    let filterData;

    setShowResetBtn(!isEmpty(filter));
    if (Object.keys(filter).length === 0) {
      return onFilterChange(mapDataToLegend({}));
    }
    if(onlySelect) {
      filterData = filter.searchBy
    } else {
      if(filter.keyword) {
        filterData = {[filter.searchBy]: filter.keyword}
      } else {
        filterData = ''
      }
    }

    return legendSearchBy
      ? onFilterChange(mapDataToLegend(legend, filter))
      : onFilterChange(filterData);
  };

  const resetFilter = () => {
    if (searchFilterRef) {
      searchFilterRef.current.reset();
      setFilter({});
      onFilterChange({});
      setShowResetBtn(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="d-flex align-items-center mb-3 mb-md-0 activities-filter">
      <SearchByColumn
        columnOptions={searchByColumnOptions}
        onChange={onSearchChange}
        ref={searchFilterRef}
        onKeyUp={handleKeyPress}
        defaultSelect={currentFilter?.search_by || defaultSelect}
        onlySelect={onlySelect}
      />
      <div className="ml-3">
        <Button
          type="button"
          onClick={handleSubmit}
          generalType="default"
          disabled={!isFilterReady}
        >
          Filter
        </Button>
        {
          showResetBtn && (
            <Button
              className="ml-2"
              type="button"
              onClick={resetFilter}
              generalType="default-outlined"
            >
              <i className="mdi mdi-refresh icon" />
              Clear
            </Button>
          )
        }
      </div>
    </div>
  );
};

TableFilters.propTypes = {
  searchByColumnOptions: PropTypes.arrayOf(PropTypes.object),
  onFilterChange: PropTypes.func.isRequired,
  userId: PropTypes.number,
  currentFilter: PropTypes.object,
};

export default TableFilters;
