import React, { useState, useEffect } from 'react';
import { Col, CustomInput, Label, Row, FormGroup } from 'reactstrap';
import * as PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getEXLogo, updateEXLogo } from '../../store/actions';
import IconRefresh from '../../images/icon-refresh.svg';
// import AhoyTrack from '../../modules/AhoyTrack';

const CustomizeExtension = ({ currentEXLogo, dispatchUpdateEXLogo, dispatchGetEXLogo }) => {
  const [ imageSrc, setImageSrc ] = useState(null);

  // AhoyTrack('input');

  useEffect(() => {
    dispatchGetEXLogo();
  }, []);

  const changeImage = (e) => {
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append('chrome_ex_logo', file);
    const fileSize = (file.size / (1024 * 1024)).toFixed(2);
    const validMimeTypes = [
      'image/jpeg', 'image/png', 'image/gif',
    ];

    if (!validMimeTypes.includes(file.type)) {
      toastr.error('Supported files are JPG, PNG, GIF');
      return;
    }

    if (fileSize > 10) {
      toastr.error('File size must be up to 10MB');
      return;
    }

    setImageSrc(URL.createObjectURL(file));
    dispatchUpdateEXLogo(formData);
  };

  return (
    <>
      <div className="title">Add a custom logo to your extension</div>
      <div className="subtitle">
        Upload a customer or company logo below for your end users to see every time they run their HubbleIQ+ extension.
      </div>
      <div className="addlogo">
        <Row>
          <Col md="6">
            <div className="addlogo-info">
              <div className="onboarding-block">
                <div className="block-title">Current logo</div>
                <div className="addlogo-logo">
                  { imageSrc && <img src={imageSrc} alt="logo" className="img-responsive" /> }
                  { !imageSrc && currentEXLogo && <img src={currentEXLogo} alt="logo" className="img-responsive" /> }
                </div>
                <FormGroup className="form-file mt-3">
                  <Label for="onboarding-logo-extension" className="button button-default">
                    <CustomInput type="file" name="chrome_ex_logo" id="onboarding-logo-extension" onChange={changeImage} />
                    Browse
                  </Label>
                </FormGroup>
              </div>
              <div className="onboarding-block">
                <div className="addlogo-file">
                  <div className="block-title">File upload</div>
                  <ul className="addlogo-file-list">
                    <li>White images & logos recommended</li>
                    <li>Files supported are JPG, PNG, GIF, up to 10MB file size</li>
                    <li>Recommended dimensions are 275x55</li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="addlogo-preview">
              <div className="block-title">Preview</div>
              <div className="extension-preview-wrapper">
                <div className="extension-preview good">
                  <div className="extension-preview-header">
                    <div className="extension-preview-header-top">
                      <div className="extension-preview-logo">
                        { imageSrc && <img src={imageSrc} alt="logo" className="img-responsive" /> }
                        { !imageSrc && currentEXLogo && <img src={currentEXLogo} alt="logo" className="img-responsive" /> }
                      </div>
                      <div className="extension-preview-update">
                        Updated just now
                        <div className="icon">
                          <img src={IconRefresh} alt="refresh icon" className="img-responsive" />
                        </div>
                      </div>
                    </div>
                    <div className="extension-preview-title">
                      Excellent WiFi for getting work done.
                    </div>
                    <div className="extension-preview-subtitle">
                      Your internet performance is optimal. You can expect to do everything and more.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

CustomizeExtension.defaultProps = {
    currentEXLogo: null,
  };

  CustomizeExtension.propTypes = {
    dispatchUpdateEXLogo: PropTypes.func.isRequired,
    currentEXLogo: PropTypes.string,
  };

  const mapStateToProps = (state) => {
    const { currentEXLogo } = state.EXConfig;
    return { currentEXLogo };
  };

  const mapDispatchToProps = {
    dispatchGetEXLogo: getEXLogo,
    dispatchUpdateEXLogo: updateEXLogo,
  };

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomizeExtension));
