import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';
import { fetchAnalytics } from '../../store/actions';
import DashboardDeviceCard from './DashboardDeviceCard';

const DashboardDevices = () => {
  const {
    battery_health,
    cpu_utilization,
    operating_system_category,
    users_last_restart_time_threshold,
    device_performance_summary_isLoading: devicesIsLoading,
  } = useSelector((state) => state.DashboardAnalytics);
  const { data: profileData } = useSelector((state) => state.Profile);
  const dispatch = useDispatch();

  const devices = {
    cpu_utilization,
    battery_health,
    operating_system_category,
    users_last_restart_time_threshold,
  };

  useEffect(() => {
    dispatch(fetchAnalytics('device_performance_summary'));
  }, []);

  return (
    <>
      {devicesIsLoading && <div className="spinner-wrapper"><Spinner color="primary" /></div>}
      <Row>
        {
          devices
          ? (
            Object?.values(devices)?.map((device, i) => (
              <Col lg="4" md="6" className="mb-3" key={i}>
                <DashboardDeviceCard
                  device={device}
                  title={Object.keys(devices)?.[i]}
                  lastRestartTime={profileData?.last_restart_time_threshold}
                />
              </Col>
            ))
          )
          : <Col className="text-center"><h5>Nothing to see here yet, but hold on tight! User performance data will appear once accumulated.</h5></Col>
        }
      </Row>
    </>
  );
};

export default DashboardDevices;
