import React, {useEffect, useState} from 'react';
import styles from './user-online-ststus.module.scss';
import {useRouteMatch} from "react-router-dom/cjs/react-router-dom";
import {fetchUserData} from "../../store/testresults/userstatus/actions";
import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "reactstrap";

function UserOnlineStatus({ userId }) {
	const match = useRouteMatch();
	const dispatch = useDispatch();
	const {userData, userDataisLoading: loading} = useSelector(state => state.UserStatus);
	const [id] = useState(match?.params?.id);
	const [status, setStatus] = useState('');
	const isOnlineStyle = userData?.is_online ? styles.Online : styles.Offline
	
	useEffect(() => {
		if (id || userId) {
			dispatch(fetchUserData(id || userId));
		}
	}, [id, userId]);
	useEffect(() => {
		if (userData) {
			setStatus(userData?.is_online ? 'Online' : 'Offline');
		}
	}, [userData])
	
	return (
		<div className={styles.avatarContainer}>
			{loading ? (<div className="spinner-wrapper"><Spinner color="primary" /></div>) : (<div className={`${styles.statusIndicator} ${isOnlineStyle}`}>{status}</div>)}
		</div>
	);
}

export default UserOnlineStatus;
