import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import queryString from 'query-string';
import Pagination from '../../components/pagination';
import PageLoader from '../../components/PageLoader';
import ApplicationsEventsTable from '../../components/tables/ApplicationsEventsTable';
import ApplicationsEventsTableFilter from '../../components/tables-filters/ApplicationsEventsTableFilter';
import { buildPaginationData } from '../../utility/utils';
import { getApplicationsEvents } from "../../store/application/actions";

const ApplicationsEvents = () => {
  const dispatch = useDispatch();
  const {error, loading, appsEvents} = useSelector((state) => state.Applications);
  const [filter, setFilter] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const fetchData = useCallback((page = 1) => {
    let queryParams = { };
    
    if (page) {
      queryParams.page = page;
    }
    
    if (sortBy) {
      queryParams.sort_by = sortBy;
    }
    
    if (filter) {
      queryParams = { ...queryParams, ...filter };
    }
    dispatch(getApplicationsEvents(queryParams))
  }, [sortBy, filter]);

  useEffect(() => {
    if (location.search) {
      const urlSearch = queryString.parse(location.search);
      setFilter({ search_by: urlSearch.search_by });
    }
  }, [location]);
  
  const onFilterChange = (filter) => {
    history.replace({ search: '' });
    setFilter(filter);
  };
  
  useEffect(() => {
    if (!location.search) {
      fetchData();
    }
  }, []);
  
  useEffect(() => {
    if (filter) {
      fetchData();
    }
  }, [filter]);
  
  useEffect(() => {
    if (appsEvents?.activities?.length) {
      setPaginationData(buildPaginationData(appsEvents));
    }
  }, [appsEvents]);
  
  const onTableSortChange = (sortBy) => {
    if (sortBy) {
      setSortBy(sortBy);
    }
  };
  
  const navigateToPage = (page) => {
    fetchData(page);
  };
  
  useEffect(() => {
    if (typeof sortBy === 'string') {
      fetchData();
    }
  }, [sortBy]);
  
  if (error) {
    return (<PageLoader />);
  }

  return (
    <div className="container-fluid content">
      <Row className="page-title align-items-center">
        <Col sm={6}>
          <div className="page-title-box">
            <h2>Applications Events</h2>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center test-results-table-container">
        <Col xl={12}>
          <div className="justify-between mb-4 d-md-flex">
            <ApplicationsEventsTableFilter onFilterChange={onFilterChange} currentFilter={filter} />
          </div>
          <div className="table-responsive">
            <ApplicationsEventsTable
              fetch={fetchData}
              data={appsEvents?.activities}
              isLoading={loading}
              onSortChange={onTableSortChange}
            />
          </div>
          {(appsEvents?.activities?.length > 0 && paginationData) && <Pagination paginationData={paginationData} navigateTo={navigateToPage} />}
        </Col>
      </Row>
    </div>
  );
};

export default ApplicationsEvents;
