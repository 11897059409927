import { useState } from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { Button } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import styles from './stripe.module.scss';

const StripeCheckoutForm = ({changeView}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/admin/subscriptions`,
      },
    });

    if (result.error) {
      toastr.error(result.error?.message);
    } else if(result.paymentIntent && result.paymentIntent.status === 'succeeded') {
      toastr.success(`Payment status: ${result.paymentIntent.status}`);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.stripeForm}>
      <div className={styles.stripeFormElements}>
        <PaymentElement />
      </div>
      <div className={styles.stripeFormActions}>
        <Button
          disabled={isProcessing}
          className="button button-default"
        >
          {isProcessing ? 'Processing...' : 'Submit'}
        </Button>
        <Button
          onClick={() => changeView(false)}
          className="button button-elevated"
        >
          Cancel
        </Button>
      </div>
    </form>
  )
};

export default StripeCheckoutForm;