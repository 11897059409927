import React, {useState} from 'react';
import {Col, FormGroup, Label, Row, Spinner} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from '../../Button/Button';
import styles from './bottom-banner.module.scss';
import {toastr} from "react-redux-toastr";
import apiClient from "../../../gears/apiClient";
import {useSelector} from "react-redux";

const BottomBanner = () => {
	const { helpdeskMessage } = useSelector(state => state.EXConfig);
	const extensionSchema = Yup.object().shape({
		extension_bottom_banner: Yup.string().required('Field is required'),
	});
	
	const [preview, setPreview] = useState(true);
	
	const onPreviewClick = () => {
		setPreview(!preview);
	};
	
	const updateBannerHanlder = async (text) => {
		try {
			await apiClient.post('/settings/update-footer-message', { footerMessage: text });
			toastr.success('Updated succesfully');
		} catch (e) {
			toastr.error('Banner update failed')
			console.error(e);
		}
	}
	
	return (
		<div className="settings-tab-block">
			<div className="title">Bottom banner content</div>
			<div className="subtitle">
				By configuring the banner message, users will see it in their Chrome extension.
			</div>
			<Formik
				initialValues={{
					extension_bottom_banner: helpdeskMessage?.footer_message || '',
				}}
				validationSchema={extensionSchema}
				onSubmit={(values) => updateBannerHanlder(values.extension_bottom_banner)}
				enableReinitialize
			>
				{({ handleChange, values, errors }) => (
					<Form className="form form-helpdesk">
						<Row>
							<Col xl="6">
								<div className="settings-tab-item">
									<Row>
										<Col md={10}>
											<FormGroup>
												<Label for="extension_bottom_banner">Banner Text</Label>
												<Field
													id="extension_bottom_banner"
													className={`form-control ${errors.extension_bottom_banner ? 'is-invalid' : ''}`}
													name="extension_bottom_banner"
													type="textarea"
													onChange={(e) => handleChange(e)}
													as="textarea"
												/>
											</FormGroup>
											<div className="form-btn d-flex justify-content-between">
												<Button type="submit" generalType="default">Save changes</Button>
												<Button generalType="default" onClick={onPreviewClick}>Preview</Button>
											</div>
										</Col>
									</Row>
								</div>
							</Col>
							<Col xl="6">
								{preview && (
									<div className={styles.bottomBanner}>
										<div
											className={styles.text}
											dangerouslySetInnerHTML={{ __html: values.extension_bottom_banner }}
										/>
									</div>
								)}
							</Col>
						</Row>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default BottomBanner;
