import React, { useState } from 'react';
import { Button } from 'reactstrap';
import InviteCsvForm from '../../forms/InviteCsvForm';
import InviteForm from '../../forms/InviteForm';
import DomainEnrollment from './DomainEnrollment';
import InvitationSent from './InvitationSent';

const EnrollUsers = () => {
  const [ activeTab, setActiveTab ] = useState(0);
  const [ copyToClipboard, setCopyToClipboard ] = useState(null);
  const [ sentTo, setSentTo ] = useState(null);

  const changeActiveTab = (number) => {
    setActiveTab(number);
  };

  const sentToEmail = (email) => {
    setSentTo(email);
  };

  const textToCopy = 'cbfnbdgmjknfjeidohbhmpdliofaopne';

  const copyText = () => {
    setCopyToClipboard(textToCopy);
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <div className="enrollusers">
      <div className="enrollusers-tabs">
        {activeTab === 0 && (
          <div className="enrollusers-tab">
            <div className="title">Enroll End-Users</div>
            <div className="subtitle">
              Fill in the form below to send an invite e-mail to your end-users. See below for other methods of inviting and enrolling users.
            </div>
            <div className="onboarding-form">
              <InviteForm changeActiveTab={changeActiveTab} sentToEmail={sentToEmail} />
            </div>
            <div className="enrollusers-tabs-nav">
              <div>
                Have a list of users?
                <Button color="link" onClick={() => changeActiveTab(1)}>Batch Invite</Button>
              </div>
              <div>
                Invite via Google Admin?
                <Button color="link" onClick={() => changeActiveTab(2)}>Click here</Button>
              </div>
            </div>
          </div>
        )}
        {activeTab === 1 && (
          <div className="enrollusers-tab">
            <div className="title">Batch Enroll End-Users</div>
            <div className="subtitle">
              Download and fill in the provided import template to invite many users at once. Once filled in, save the file as a .CSV file and upload it below.
            </div>
            <div className="onboarding-form">
              <InviteCsvForm changeActiveTab={changeActiveTab} sentToEmail={sentToEmail} />
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <div className="enrollusers-tab">
            <div className="title">Deploy with Google Admin</div>
            <div className="subtitle">
              If you manage your end-users with Google Admin, follow the steps provided in the video below to auto-deploy and auto-enroll your end-users and groups.
            </div>
            <div className="video">
              <iframe
                title="welcome video"
                src="https://player.vimeo.com/video/616936937?h=8b2e0b3e85&title=0&byline=0&portrait=0"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="enrollusers-copy">
              Chrome Extension ID: <span>cbfnbdgmjknfjeidohbhmpdliofaopne</span>
              <Button color="link" onClick={copyText}>
                <i className="mdi mdi-content-copy font-size-16 align-middle mr-1" />
                {copyToClipboard ? 'Copied!' : 'Copy'}
              </Button>
            </div>
            <div className="enrollusers-actions">
              {/* <a href="https://admin.google.com/" rel="noreferrer" target="_blank" className="btn">Open Google Admin</a> */}
              <Button className="button button-default" onClick={() => changeActiveTab(4)}>Specify Your Domain</Button>
            </div>
          </div>
        )}
        {activeTab === 3 && (
          <InvitationSent changeActiveTab={changeActiveTab} sentTo={sentTo} />
        )}
        {activeTab === 4 && <DomainEnrollment />}
      </div>
    </div>
  );
};

  export default EnrollUsers;
