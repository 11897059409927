import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, Button } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiToyBrickPlus } from '@mdi/js';
import { withRouter } from 'react-router-dom';

const HeaderInvite = ({ toggleModal }) => {
  const [ dropdownOpen, setDropdownOpen ] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className="header-invite">
        <DropdownToggle className="btn waves-effect btn-popup mr-0" tag="button">
          <span>Invite</span>
        </DropdownToggle>
        <DropdownMenu right>
          <Button color="link" className="dropdown-item" onClick={() => toggleModal('install')}>
            <Icon path={mdiToyBrickPlus} size="17px" className="align-middle mr-1" />
            <span>Install Chrome extension</span>
          </Button>
          <Button color="link" className="dropdown-item" onClick={() => toggleModal('invite')}>
            <i className="mdi mdi-account-multiple-plus font-size-17 align-middle mr-1" />
            <span>Invite extension users</span>
          </Button>
          <Button color="link" className="dropdown-item" onClick={() => toggleModal('admin')}>
            <i className="mdi mdi-account-plus font-size-17 align-middle mr-1" />
            <span>Invite an admin</span>
          </Button>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withRouter(HeaderInvite);
