import React from 'react';
import { Button } from 'reactstrap';
import iconSentSuccess from '../../images/sent-success.svg';

const InvitationSent = ({ changeActiveTab, isAdmin, sentTo }) => {
  return (
    <div className="invitation">
      <div className="invitation-img">
        <img src={iconSentSuccess} alt="icon sent success" className="img-responsive" />
      </div>
      <div className="title">Invitation Sent+</div>
      <div className="subtitle">
        {
          sentTo === 'file'
          ? 'Each of your users will receive an invite'
          : `Your invitation has been successfully sent to ${sentTo}!`
        }
      </div>
      <div className="invitation-actions">
        <Button color="link" onClick={() => changeActiveTab(0)}>Invite another {isAdmin ? 'admin' : 'user'}</Button>
      </div>
      {(changeActiveTab && !isAdmin) && (
        <div className="enrollusers-tabs-nav">
          <div>
            Have a list of users?
            <Button color="link" onClick={() => changeActiveTab(1)}>Batch Invite</Button>
          </div>
          <div>
            Invite via Google Admin?
            <Button color="link" onClick={() => changeActiveTab(2)}>Click here</Button>
          </div>
        </div>
      )}
    </div>
  );
};

  export default InvitationSent;
