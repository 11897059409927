import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';
import { fetchAnalytics } from '../../store/actions';
import DashboardCard from './DashboardCard';
import IconRecentOutages from '../../images/icon-recent-outages.svg';
import IconExclamation from '../../images/icon-exclamation.svg';

const DashboardOutage = () => {
  const {
    current_outage: currentOutage,
    current_outage_isLoading: currentOutageIsLoading,
  } = useSelector((state) => state.DashboardAnalytics);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAnalytics('current_outage'));
  }, []);

  return (
    <>
      {currentOutageIsLoading && <div className="spinner-wrapper"><Spinner color="primary" /></div>}
      <Row>
        <Col md="6" lg="4" className="mb-3 mb-lg-0">
          <DashboardCard
            cardBlocks={[
              {
                icon: IconExclamation,
                iconClass: 'danger',
                linkText: 'Current Disruptions',
                linkUrl: '/admin/applications-events?search_by=status&keyword=false',
                value: currentOutage?.[0]?.current_outage_last_hour_count || 0,
              },
            ]}
          />
        </Col>
        <Col md="6" lg="4" className="mb-3 mb-lg-0">
          <DashboardCard
            cardBlocks={[
              {
                icon: IconRecentOutages,
                iconClass: 'decent',
                linkText: 'Recent Disruptions',
                linkUrl: '/admin/applications-events?search_by=status&keyword=false',
                value: currentOutage?.[0]?.current_outage_last_24_hour_count || 0,
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default DashboardOutage;
