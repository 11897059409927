export const FETCH_COMPANY_DETAILS = 'FETCH_COMPANY_DETAILS';
export const FETCH_COMPANY_DETAILS_SUCCESS = 'FETCH_COMPANY_DETAILS_SUCCESS';
export const FETCH_COMPANY_DETAILS_API_ERROR = 'FETCH_COMPANY_DETAILS_API_ERROR';
export const FETCH_COMPANY_DETAILS_WEB_TEST_STATUS = 'FETCH_COMPANY_DETAILS_WEB_TEST_STATUS';

export const FETCH_COMPANY_LOGO = 'FETCH_COMPANY_LOGO';
export const FETCH_COMPANY_LOGO_SUCCESS = 'FETCH_COMPANY_LOGO_SUCCESS';
export const FETCH_COMPANY_LOGO_API_ERROR = 'FETCH_COMPANY_LOGO_API_ERROR';

export const GET_LOGO = 'GET_LOGO';
export const GET_LOGO_SUCCESS = 'GET_LOGO_SUCCESS';
export const GET_LOGO_ERROR = 'GET_LOGO_ERROR';

export const UPDATE_COMPANY_LOGO = 'UPDATE_COMPANY_LOGO';
export const UPDATE_COMPANY_LOGO_SUCCESS = 'UPDATE_COMPANY_LOGO_SUCCESS';
export const UPDATE_COMPANY_LOGO_ERROR = 'UPDATE_COMPANY_LOGO_ERROR';

export const FETCH_USER_IP = 'FETCH_USER_IP';
export const FETCH_USER_IP_SUCCESS = 'FETCH_USER_IP_SUCCESS';
export const FETCH_USER_IP_ERROR = 'FETCH_USER_IP_ERROR';
