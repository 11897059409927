import React, {useEffect} from 'react';
import axios from 'axios';
import {Button, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {CircularProgress} from '@material-ui/core';
import {toastr} from 'react-redux-toastr';
import ProfileForm from '../../components/forms/ProfileForm';
import ProfileNewPassword from '../../components/forms/ProfileNewPassword';
import {getMyProfile, updateMyProfile} from '../../store/profile/actions';
import AuthHelper from '../../helpers/auth_helper';
import AhoyTrack from '../../modules/AhoyTrack';
import apiClient from '../../gears/apiClient';
import ColorScheme from '../../components/profile/ColorScheme';

const Profile = ({profile, loading, getMyProfile, history, updateMyProfile, location}) => {
  const authHelper = new AuthHelper();
  const token = authHelper.getAuthenticatedUser();

  AhoyTrack();

  useEffect(() => {
    if (!profile && !loading) {
      getMyProfile(null, history);
    }
  }, [getMyProfile, profile, loading]);

  useEffect(() => {
    if (location.search.includes('unsubscribe')) {
      updateMyProfile({send_digest: false}, history, 'toastr');
    }
  }, []);

  const updateSendDigest = () => {
    updateMyProfile({send_digest: !profile.send_digest}, history, 'toastr');
  };

  if (loading || !profile) {
    return (
      <div className="d-flex justify-content-center pt-5">
        <CircularProgress />
      </div>
    );
  }

  const onManageClick = async () => {
    try {
      const result = await apiClient.get('/verify-subscription');
      if (result.sessionId) {
        const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUB_KEY);
        await stripe.redirectToCheckout({sessionId: result.sessionId});
      } else {
        window.location.href = result.url;
      }
    } catch (error) {
      toastr.error('System', error.message);
    }
  };

  return (
    <div className="container-fluid content profile">
      <div className="mt-4">
        <div className="container p-0">
          <div className="profile-top">
            <Row>
              <Col sm={6}>
                <div className="profile-top-title">Subscription & Billing</div>
                {
                  profile.main_contact_user
                    ? (
                      <>
                        <div className="profile-top-text">
                          See billable usage, manage subscription, and edit payment information. Billing portal is only
                          available to the primary account owner.
                        </div>
                        <Button
                          color="primary"
                          onClick={onManageClick}
                        >
                          {profile.subscription_status === 'trial' ? 'Subscribe Now' : 'Manage'}
                        </Button>
                      </>
                    )
                    : (
                      <div className="profile-top-text">
                        To manage your subscription and billing information, please contact your company’s account owner.
                      </div>
                    )
                }
              </Col>
            </Row>
          </div>
          <div className="profile-top">
            <Row>
              <Col sm={6}>
                <ProfileForm profile={profile} />
              </Col>
              <Col sm={6}>
                <ProfileNewPassword profile={profile} />
              </Col>
            </Row>
          </div>
          <div className="settings settings-tab-block">
            <Row>
              <Col sm={6}>
                <div className="profile-top-title">Preferences</div>
                <FormGroup tag="fieldset">
                  <FormGroup className="form-group_checkbox">
                    <Label check>
                      E-mail me daily HubbleIQ activity summary emails
                      <Input
                        type="checkbox"
                        name="send_digest"
                        checked={profile?.send_digest}
                        onChange={updateSendDigest}
                      />{' '}
                      <span />
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="profile-top">
            <ColorScheme />
          </div>
        </div>
      </div>
    </div>
  );
};

Profile.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {data: profile, loading} = state.Profile;
  const {companyName, companyDetails: {stripe_customer_id: customerId}} = state.Company;
  return {profile, loading, customerId, companyName};
};

const mapDispatchToProps = {getMyProfile, updateMyProfile};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
