import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';

import {FETCH_COMPANY_DETAILS, FETCH_COMPANY_LOGO, FETCH_USER_IP, GET_LOGO, UPDATE_COMPANY_LOGO} from './actionTypes';
import {
  fetchCompanyDetailsSuccess, fetchCompanyDetailsApiError,
  fetchCompanyLogoSuccess, fetchCompanyLogoApiError,
  fetchUserIpSuccess, fetchUserIpError,
  setWebTestStatus,
  updateCompanyLogoSuccess, updateCompanyLogoError,
  getLogoSuccess, getLogoError,
} from './actions';

import FetchResultsHelper from '../../helpers/fetch_results_helper';
import apiClient from '../../gears/apiClient';

const fetch_results_helper = new FetchResultsHelper();

function* fetchCompanyDetails({ payload: { data } }) {
  try {
    const response = yield apiClient.get('/company_full', data);
    yield put(fetchCompanyDetailsSuccess(response));
  } catch (error) {
    if (error === 'web_test_disabled') {
      yield put(setWebTestStatus(false));
    } else {
      yield put(fetchCompanyDetailsApiError(error));
    }
  }
}

function* fetchCompanyLogo({ payload: { data} }) {
  try {
    const response = yield apiClient.get('/company_home', data);
    yield put(fetchCompanyLogoSuccess(response));
  } catch (error) {
    yield put(fetchCompanyLogoApiError(error));
  }
}

function* getLogo(condition) {
  try {
    const response = yield apiClient.get('/settings/get_current_company_logo');
    if (!condition) {
      yield put(getLogoSuccess(response));
    }
  } catch (error) {
    yield put(getLogoError(error));
    toastr.error('System', error);
  }
}

function* updateCompanyLogo({ payload }) {
  try {
    const response = yield apiClient.post('/settings/new_company_logo', payload);
    yield put(updateCompanyLogoSuccess(response));
    toastr.success('System', 'Success logo update');
  } catch (error) {
    yield put(updateCompanyLogoError(error));
    toastr.error('System', error);
  }
}

function* fetchUserIp({ payload: { data, history } }) {
  try {
    const response = yield call(fetch_results_helper.fetchUserIp, data, history);

    yield put(fetchUserIpSuccess(response.data));
  } catch (error) {
    yield put(fetchUserIpError(error));
  }
}

export function* watchCompanyDetailsFetch() {
  yield takeEvery(FETCH_COMPANY_DETAILS, fetchCompanyDetails);
}

export function* watchCompanyLogoFetch() {
  yield takeEvery(FETCH_COMPANY_LOGO, fetchCompanyLogo);
}

export function* watchUserIpFetch() {
  yield takeEvery(FETCH_USER_IP, fetchUserIp);
}

export function* watchGetLogo() {
  yield takeEvery(GET_LOGO, getLogo);
}

export function* watchCompanyLogoUpdate() {
  yield takeEvery(UPDATE_COMPANY_LOGO, updateCompanyLogo);
}

function* CompanySaga() {
  yield all([
    fork(watchCompanyDetailsFetch),
    fork(watchCompanyLogoFetch),
    fork(watchUserIpFetch),
    fork(watchCompanyLogoUpdate),
    fork(watchGetLogo),
  ]);
}

export default CompanySaga;
