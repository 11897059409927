import * as c from './actionTypes';

const initialState = {
  error: null,
  loading: false,
  dashboard_data: {},
  dashboard_loading: true,
  dashboard_error: null,
};

const fetchDashboardAnalytics = (state = initialState, action) => {
  const { type, payload, history } = action;
  switch (type) {
    case c.FETCH_DASHBOARD_ANALYTICS:
      return {
        ...state,
        history,
        loading: true,
      };
    case c.FETCH_DASHBOARD_ANALYTICS_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case c.FETCH_DASHBOARD_ANALYTICS_API_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case c.FETCH_DASHBOARD_QUERY:
      return {
        ...state,
      };
    case c.FETCH_DASHBOARD_QUERY_SUCCESS:
      return {
        ...state,
        dashboard_data: payload,
        dashboard_loading: false,
      };
    case c.FETCH_DASHBOARD_QUERY_ERROR:
      return {
        ...state,
        dashboard_loading: false,
        dashboard_error: payload,
      };
    case c.FETCH_ANALYTICS:
      return {
        ...state,
        [`${payload}_isLoading`]: true,
        [`${payload}_error`]: null,
      };
    case c.FETCH_ANALYTICS_SUCCESS:
      return {
        ...state,
        ...payload,
        [`${action.key}_isLoading`]: false,
      };
    case c.FETCH_ANALYTICS_ERROR:
      return {
        ...state,
        [`${action.key}_error`]: payload,
        [`${action.key}_isLoading`]: false,
      };
    default:
      return state;
  }
};

export default fetchDashboardAnalytics;
