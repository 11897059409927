import { takeEvery, fork, put, all } from 'redux-saga/effects';

import {CHANGE_ISSUE_STATUS, FETCH_USER_ISSUES_LOGS} from './actionTypes';
import {
	fetchUserIssuesLogsSuccess,
	fetchUserIssuesLogsError,
	changeIssueStatusSuccess,
	changeIssueStatusError
} from './actions';
import apiClient from "../../gears/apiClient";
import {toastr} from "react-redux-toastr";
function* fetchUserIssuesLogs({ payload: { id, status = 'active', itemCount = '10' } }) {
	try {
		const response = yield apiClient.get(`/user-disruption-logs?userId=${id}&status=${status}&items=${itemCount}`);
		yield put(fetchUserIssuesLogsSuccess(response?.issues));
	} catch (error) {
		yield put(fetchUserIssuesLogsError(error));
	}
}

function* changeIssueStatus({ payload: { id, status, completionNotes = '', sendEmail = false } }) {
	try {
		const response = yield apiClient.put(`/update-disruption-status/${id}`, { status, completionNotes, sendEmail });
		toastr.success('The issue has been successfully solved!');
		yield put(changeIssueStatusSuccess(response));
	} catch (error) {
		yield put(changeIssueStatusError(error));
	}
}

export function* watchchangeIssueStatus() {
	yield takeEvery(CHANGE_ISSUE_STATUS, changeIssueStatus);
}

export function* watchFetchUserIssuesLogs() {
	yield takeEvery(FETCH_USER_ISSUES_LOGS, fetchUserIssuesLogs);
}

function* userIssuesLogsSaga() {
	yield all([
		fork(watchFetchUserIssuesLogs),
		fork(watchchangeIssueStatus),
	]);
}

export default userIssuesLogsSaga;
