import {
  FETCH_COMPANY_DETAILS, FETCH_COMPANY_DETAILS_SUCCESS, FETCH_COMPANY_DETAILS_API_ERROR,
  FETCH_COMPANY_LOGO, FETCH_COMPANY_LOGO_SUCCESS, FETCH_COMPANY_LOGO_API_ERROR,
  FETCH_USER_IP, FETCH_USER_IP_SUCCESS, FETCH_USER_IP_ERROR,
  FETCH_COMPANY_DETAILS_WEB_TEST_STATUS,
  UPDATE_COMPANY_LOGO, UPDATE_COMPANY_LOGO_SUCCESS, UPDATE_COMPANY_LOGO_ERROR,
  GET_LOGO, GET_LOGO_SUCCESS, GET_LOGO_ERROR,
} from './actionTypes';

const initialState = {
  error: null,
  companyDetailsLoading: true,
  loading: false,
  companyDetails: {},
  companyLogo: null,
  companyName: null,
  isWebTestEnabled: true,
  currentLogo: null,
  trial: null,
  trial_expiration: null,
};

const fetchCompanyData = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY_DETAILS:
      return {
        ...state,
        data: action.data,
        history: action.history,
        companyDetailsLoading: true,
      };
    case FETCH_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companyDetails: action.payload,
        companyDetailsLoading: false,
      };
    case FETCH_COMPANY_DETAILS_API_ERROR:
      return { ...state, error: action.payload, companyDetailsLoading: false };
    case FETCH_COMPANY_DETAILS_WEB_TEST_STATUS:
      return { ...state, isWebTestEnabled: action.payload, companyDetailsLoading: false };
    case FETCH_COMPANY_LOGO:
      return {
        ...state,
        data: action.data,
        history: action.history,
        loading: true,
      };
    case FETCH_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        companyLogo: action.payload.company_logo,
        companyName: action.payload.name,
        trial: action.payload.trial,
        trial_expiration: action.payload.trial_expiration,
        loading: false,
      };
    case FETCH_COMPANY_LOGO_API_ERROR:
      return { ...state, error: action.payload, loading: false };
    // Logo new url
    case GET_LOGO:
      return {
        ...state,
      };
    case GET_LOGO_SUCCESS:
      return {
        ...state,
        companyLogo: action.payload.logo,
      };
    case GET_LOGO_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    // End Logo
    case UPDATE_COMPANY_LOGO:
      return {
        ...state,
        data: action.data,
        history: action.history,
        loading: true,
      };
    case UPDATE_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        companyLogo: action.payload.logo,
        loading: false,
      };
    case UPDATE_COMPANY_LOGO_ERROR:
      return { ...state, error: action.payload, loading: false };
    case FETCH_USER_IP:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_IP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_USER_IP_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default fetchCompanyData;
