import React from 'react';
import { Redirect } from 'react-router-dom';

import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import ForgetPwd from '../pages/Authentication/FogetPassword';
import ResetPassword from '../pages/Authentication/ResetPassword';

import Dashboard from '../pages/Dashboard';
import Tests from '../pages/Tests';
import Users from '../pages/Users';
import UserStatus from '../pages/UserStatus';
import Profile from '../pages/Profile';
import ExtensionConfiguration from '../pages/ExtensionConfiguration/ExtensionConfigurtion';
import BrowserTestSettings from '../pages/BrowserTestSettings/BrowserTestSettings';
import ApplicationsSettings from '../pages/ApplicationsSettings/ApplicationsSettings';
import BluetoothSettings from '../pages/BluetoothSettings/BluetoothSettings';
import MapView from '../pages/MapView';
import IqOverview from '../pages/IqOverview/IqOverview';

import Pages404 from '../pages/ExtraPages/Pages404';
import Pages500 from '../pages/ExtraPages/Pages500';
import PagesMaintenance from '../pages/ExtraPages/PagesMaintenance';

import {
  HomePage,
  TestingPage,
  TestingResultPage,
  StartTestingPage,
} from '../pages/PublicPages';
import FirstTest from '../pages/FirstTest';
import GoogleSignIn from '../pages/GoogleSignIn';
import DashboardSettings from "../pages/DashboardSettings";
import SDKCredentials from "../pages/SDKCredentials";
import Subscriptions from "../pages/Subscriptions";
import HubChromeAuth from '../pages/HubChromeAuth/HubChromeAuth';
import Logs from '../pages/Logs';
import Issues from '../pages/Issues';
import Announcements from "../pages/Announcements/Announcements";

const isSubdomain = () => {
  const urlArr = window.location.hostname.split('.');
  if (urlArr.includes('localhost')) return true;
  return urlArr.length > 2;
}

const authProtectedRoutes = [
  { path: '/admin/digital-experience', component: Dashboard },
  { path: '/admin/users', component: Users },
  { path: '/admin/tests', component: Tests },
  { path: '/admin/profile', component: Profile },
  { path: '/admin/extension-settings', component: ExtensionConfiguration },
  { path: '/admin/dashboard-settings', component: DashboardSettings },
  { path: '/admin/subscriptions', component: Subscriptions },
  { path: '/admin/sdk-credentials', component: SDKCredentials },
  { path: '/admin/browser-test-settings', component: BrowserTestSettings },
  { path: '/admin/applications-settings', component: ApplicationsSettings },
  { path: '/admin/announcements', component: Announcements },
  { path: '/admin/bluetooth-settings', component: BluetoothSettings },
  { path: '/admin/user-status/:id', component: UserStatus },
  { path: '/admin/broadband-insights', component: MapView },
  { path: '/admin/iq-overview', component: IqOverview },
  { path: '/admin/logs', component: Logs },
  { path: '/admin/issues', component: Issues },
  { path: '/admin/first-test', component: FirstTest },
  { path: '/admin/*', component: Pages404 },

  { path: '/admin', exact: true, component: () => <Redirect to="/admin/digital-experience" /> },
];

const publicRoutes = [
  { path: '/', component: HomePage, exact: true },
  { path: '/testing', component: TestingPage, exact: true },
  { path: '/start-test', component: StartTestingPage, exact: true },
  { path: '/test-result', component: TestingResultPage, exact: true },
  { path: '/admin/logout', component: Logout },
  { path: '/admin/login', component: isSubdomain() ? Login : Pages404 },
  { path: '/google_sign_in', component: GoogleSignIn },
  { path: '/admin/forget-password', component: ForgetPwd },
  { path: '/admin/reset-password/:token', component: ResetPassword },
  { path: '/hub-chrome-auth', component: HubChromeAuth },

  { path: '/pages-404', component: Pages404 },
  { path: '/pages-500', component: Pages500 },
  { path: '/pages-Maintenance', component: PagesMaintenance },
];

export { authProtectedRoutes, publicRoutes };
