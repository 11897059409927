import React, { Component } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';

// Import Routes
import { authProtectedRoutes, publicRoutes } from './routes';
import AppRoute from './routes/route';

// layouts
import VerticalLayout from './components/VerticalLayout';
import NonAuthLayout from './components/NonAuthLayout';

import { fetchCompanyLogo, fetchCompanyDetails } from './store/actions';

// Import scss
// import './stylesheets/scss/theme.scss';
// import '../packs/main.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }

  componentDidMount() {
    const windowWidth = window.innerWidth;
    if (typeof window !== 'undefined' && windowWidth < 768) {
      window.location = 'hubbleiq://';
    }
    const { dispatchFetchCompanyLogo, dispatchFetchCompanyDetails } = this.props;
    dispatchFetchCompanyLogo();
    dispatchFetchCompanyDetails();
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    const { layout } = this.props;
    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
      // case 'horizontal':
      //   layoutCls = HorizontalLayout;
      //   break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const {companyDetailsLoading} = this.props;
    const Layout = this.getLayout();

    if (companyDetailsLoading) {
      return '';
    }

    return (
      <>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute path={route.path} layout={NonAuthLayout} component={route.component} key={idx} isAuthProtected={false} exact={route.exact} />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute path={route.path} layout={Layout} component={route.component} key={idx} isAuthProtected exact={route.exact} />
            ))}
          </Switch>
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    companyDetailsLoading: state.Company.companyDetailsLoading,
  };
};

export default connect(mapStateToProps, {
  dispatchFetchCompanyLogo: fetchCompanyLogo,
  dispatchFetchCompanyDetails: fetchCompanyDetails,
})(App);
