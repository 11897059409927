import React from 'react';
import { Link } from 'react-router-dom';

const Finish = ({ closeOnboardingModal, hide }) => {
  return (
    <>
      <div className="title">That’s it!</div>
      <div className="subtitle">
        You have completed the Setup. The deployed and installed extensions will begin to update the agent portal within 20 minutes. Use the shortcuts below to explore on your own, and remember, we are here to help!
      </div>
      <div className="finish">
        <Link to="/admin/digital-experience" className="button button-default" onClick={() => closeOnboardingModal()}>View Dashboard</Link>
        <Link to="/admin/tests" className="button button-default" onClick={() => closeOnboardingModal()}>View Test Results</Link>
        <Link to="/admin/users" className="button button-default" onClick={() => closeOnboardingModal()}>Manage users</Link>
        {!hide && <Link to="/admin/extension-settings" className="button button-default" onClick={() => closeOnboardingModal()}>Customize Extension</Link>}
      </div>

    </>
  );
};

  export default Finish;
