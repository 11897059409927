import React from 'react';
import { Button, Col } from 'reactstrap';
import styles from './subscription.module.scss';

const SubscriptionPlan = ({
  planData,
  onPlanChange,
  isStripeVisible = false,
  isDowngrade,
}) => {
	const isNumber = (value) => typeof value === 'number';
	const isEnterprise = !isNumber(planData.price) && !isNumber(planData.max_events);
	const isActive = planData.is_current;

	return (
    <div className={styles.subscriptionValues}>
      <div className={`${styles.card} ${isActive ? styles.cardActive : ''}`}>
        <h4 className={styles.cardTitle}>{planData.name}</h4>
        <p className={styles.cardText}>{planData.description}</p>
        {isNumber(planData.price) && (
          <p className={styles.cardPrice}>
            <span className={styles.textBold}>${planData.price}</span>
            /mo
          </p>
        )}
        {!isEnterprise && !isStripeVisible && !isDowngrade && (
          <Button
            className={styles.cardButton}
            disabled={isActive || !planData.price}
            onClick={() => onPlanChange(planData.id)}
          >
            {isActive ? 'Current Plan' : 'Upgrade'}
          </Button>
        )}
        {(isEnterprise || isDowngrade) && (
          <a
            className={`${styles.cardButton} ${styles.cardLink}`}
            href="https://www.hubbleiq.com/contact"
            target="_blank"
            rel="noreferrer"
          >
            Contact Us
          </a>
        )}
        {isNumber(planData.max_events) && (
          <p className={styles.cardTextBold}>{planData.max_events} Events/Month</p>
        )}
        <p className={`${styles.cardText} ${styles.cardTextBottom}`}>
          {isEnterprise
            ?	'Beyond 250,000 to unlimited events. Ideal for large enterprises and SDK customer-facing deployments.'
            : 'Access to full feature, API, SDK, Chrome Extension, and more!'
          }
        </p>
      </div>
    </div>
	)
};

export default SubscriptionPlan;
