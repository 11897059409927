import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

const DashboardBlock = ({title, subtitle, isLoading = null, children}) => {
  return (
    <div className="dashboard-block">
      {isLoading && <div className="spinner-wrapper"><Spinner color="primary" /></div>}
      {title && <div className="dashboard-block-title"><h3>{title}</h3></div>}
      {subtitle && (
        <div className="dashboard-block-subtitle">{subtitle}</div>
      )}
      {children}
    </div>
  );
};

DashboardBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export default React.memo(DashboardBlock);
